import axios, { AxiosInstance } from 'axios';
import { getConfiguration } from '../shared/utils/configuration';
import { DownloadCodeType } from '../views/CampaignsPage/types';
import { getRequestHeaders, postToSlack } from './utils';
import { ApiCampaign, ApiCampaignDetails, PostApiCampaign, ShopBasicData } from '../../../types/global';
import { CampaignUniqueCodeDetailsDto } from '@kotipizzagroup/kotipizza-campaigns-api-client';

let instance: AxiosInstance | null = null;

const getApiClient = (): AxiosInstance => {
  if (instance) return instance;

  const apiRoot = getConfiguration<string>('campaignsService.root', '');

  const client = axios.create({
    timeout: 10000,
    baseURL: apiRoot,
  });

  client.interceptors.request.use(async (config) => {
    const headers = await getRequestHeaders();
    config.headers = headers;
    return config;
  });

  instance = client;
  return client;
};

export const fetchCampaignTypes = async (): Promise<string[]> => {
  const client = getApiClient();
  const { data } = await client.get('/Campaigns/types');
  return data;
};

export const fetchAllCampaignsShops = async (): Promise<ShopBasicData[]> => {
  const client = getApiClient();
  const { data } = await client.get('/Campaigns/all-shops');
  return data;
};

export const fetchCampaigns = async (campaignType: string): Promise<ApiCampaign[]> => {
  const client = getApiClient();
  const { data } = await client.get(`/Campaigns/${campaignType}?onlyActive=false`);
  return data;
};

export const fetchCampaign = async (campaignType: string, campaignId: string): Promise<ApiCampaignDetails> => {
  const client = getApiClient();
  const { data } = await client.get(`/Campaigns/${campaignType}/${campaignId}`);
  return data;
};

export const fetchCampaignCodesExcel = async (
  campaignId: string,
  downloadCodeType: DownloadCodeType
): Promise<{ data?: Blob; error?: unknown }> => {
  const client = getApiClient();
  try {
    const { data } = await client.get(`/Campaigns/${campaignId}/unique-codes/excel`, {
      responseType: 'blob',
      params: { downloadCodeType },
      validateStatus: function (status) {
        return status == 200;
      },
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const fetchClaimCodesCsv = async (campaignIds: string[]): Promise<Blob> => {
  const client = getApiClient();
  const { data } = await client.post(
    '/Campaigns/actions/download/claims-codes',
    {
      campaignIds,
    },
    {
      responseType: 'blob',
    }
  );
  return data;
};

export const createCampaign = async (campaignType: string, campaignData: unknown): Promise<ApiCampaign[]> => {
  const client = getApiClient();
  const { data } = await client.post<ApiCampaign[]>(`/Campaigns/${campaignType}`, campaignData);
  if (data.length > 0) {
    postToSlack(`${campaignType}, ${data[0].name} created with id: ${data[0].campaignId}`);
  }

  return data;
};

export const updateCampaign = async (campaignType: string, campaignData: PostApiCampaign): Promise<number> => {
  const client = getApiClient();
  const { status } = await client.post(`/Campaigns/${campaignType}/update`, campaignData);
  return status;
};

export const fetchAllCampaignDeliveryMethods = async (): Promise<string[]> => {
  const client = getApiClient();
  const { data } = await client.get('/campaigns/delivery-methods');

  return data;
};

export const getSingleCampaignCode = async (code: string): Promise<CampaignUniqueCodeDetailsDto> => {
  const client = getApiClient();
  const { data } = await client.get(`/Campaigns/campaignCode/${code}`);
  return data;
};
