type RestaurantsPageState = {
  tabs: {
    info: {
      tab: number;
      page: number;
    };
    paymentMethods: {
      tab: number;
      page: number;
    };
  };
  currentTab: number;
  showInactive: boolean;
  websaleOnly: boolean;
};

const defaultState: RestaurantsPageState = {
  tabs: {
    info: {
      tab: 0,
      page: 0,
    },
    paymentMethods: {
      tab: 1,
      page: 0,
    },
  },
  currentTab: 0,
  showInactive: false,
  websaleOnly: false,
};

const getState = (): RestaurantsPageState => {
  const stateString = sessionStorage.getItem('restaurantPageState');
  if (!stateString) return defaultState;
  const state = JSON.parse(stateString) as RestaurantsPageState;
  return state;
};

const setState = (state: Partial<RestaurantsPageState>): void => {
  const currentState = getState();
  const newState: RestaurantsPageState = {
    ...currentState,
    ...state,
  };
  sessionStorage.setItem('restaurantPageState', JSON.stringify(newState));
};

export const restaurantPageState = {
  setState,
  getState,
};
