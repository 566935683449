import { Box, Button, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DataGridContainer } from '../RestaurantsPage/tabs/Info';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useAllProductMenus } from '../../apis/products-api';
import { HasAllPermissions, PRODUCTS_WRITE } from '../../shared/utils/permissions';
import { useUserPermissions } from '../../apis/user-details';
import { TopBar } from '../ProductsPage';
import ProductMenuDetails from './ProductMenuDetails';

type Row = {
  id: string;
  displayName: string;
  products: string;
  restaurants: string;
  description: string;
  active: boolean;
};

const ProductMenusPage = () => {
  const { data: menus = [] } = useAllProductMenus();
  const { roles: userPermissions } = useUserPermissions();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateNewMenu = () => {
    navigate('/product-menus/new');
  };

  const columns: GridColDef<Row>[] = [
    {
      field: 'displayName',
      headerName: t('productMenus.list.name'),
      flex: 2,
      renderCell(params) {
        return <Link to={params.row.id}>{params.row.displayName}</Link>;
      },
    },
    {
      field: 'products',
      headerName: t('productMenus.list.products'),
      flex: 1,
    },
    {
      field: 'restaurants',
      headerName: t('productMenus.list.restaurants'),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('productMenus.list.description'),
      flex: 4,
    },
    {
      field: 'active',
      headerName: '',
      flex: 1,
      align: 'right',
      renderCell: (params) => {
        return (params.value as boolean) ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
  ];

  const rows: Row[] = menus.map((r) => ({
    id: r.productMenuId?.toString() || '',
    displayName: r.name,
    products: r.productIds?.length ? `${r.productIds.length} kpl` : '0',
    restaurants: r.shopsHavingMenu?.length ? `${r.shopsHavingMenu.length} kpl` : '0',
    description: r.description || '',
    active: r.isActive || false,
  }));

  return (
    <Routes>
      <Route path=":id" element={<ProductMenuDetails />} />
      <Route
        index
        element={
          <Box>
            <TopBar>
              <Typography variant="h1">{t('productMenus.list.title')}</Typography>
              <Button
                disabled={!HasAllPermissions(userPermissions || [], [PRODUCTS_WRITE])}
                variant="contained"
                onClick={handleCreateNewMenu}
                color="primary"
              >
                {t('productMenus.addMenu')}
              </Button>
            </TopBar>
            <DataGridContainer>
              <DataGrid columns={columns} rows={rows} />
            </DataGridContainer>
          </Box>
        }
      />
    </Routes>
  );
};

export default ProductMenusPage;
