import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { getConfiguration } from './app/shared/utils/configuration';

const loggerCallback = (logLevel: LogLevel, message: string, containsPii: boolean) => {
  if (containsPii) return;
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
};

class AuthProvider {
  config: Configuration | undefined;
  msalAuthProvider: PublicClientApplication | undefined;

  getLoginRequest(): RedirectRequest {
    return { scopes: [...this.getAuthScopes(), 'openid', 'email'] };
  }

  init(): PublicClientApplication {
    this.config = {
      auth: {
        authority: getConfiguration<string>('auth.authority', ''),
        clientId: getConfiguration<string>('auth.clientId', '') || '',
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: loggerCallback,
          piiLoggingEnabled: false,
          logLevel: LogLevel.Warning,
        },
      },
    };

    this.msalAuthProvider = new PublicClientApplication(this.config);

    const accounts = this.msalAuthProvider.getAllAccounts();
    if (accounts.length > 0) {
      this.msalAuthProvider.setActiveAccount(accounts[0]);
    }

    this.msalAuthProvider.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const account = (event.payload as AuthenticationResult).account;
        if (this.msalAuthProvider) {
          this.msalAuthProvider.setActiveAccount(account);
        }
      }
    });

    return this.msalAuthProvider;
  }

  getAuthScopes(): string[] {
    return getConfiguration<string>('auth.scopes', '').split(',');
  }

  getMsalAuthProvider(): PublicClientApplication {
    if (!this.msalAuthProvider) return this.init();
    return this.msalAuthProvider;
  }

  getAccountInfo() {
    return this.msalAuthProvider?.getActiveAccount();
  }

  requestApiAccessToken() {
    const scopes = this.getAuthScopes();

    const parameters = {
      scopes: [...scopes],
    };

    return this.getAccessToken(parameters);
  }

  requestGraphToken() {
    const parameters = {
      scopes: ['https://graph.microsoft.com/User.Read'],
    };

    return this.getAccessToken(parameters);
  }

  logout() {
    this.getMsalAuthProvider().logoutRedirect();
  }

  private getAccessToken(parameters: SilentRequest) {
    return this.getMsalAuthProvider().acquireTokenSilent(parameters);
  }
}

export const authProvider = new AuthProvider();
