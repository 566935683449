import get from 'lodash/get';

export const getConfiguration = <T extends number | string | string[]>(key: string, valueIfMissing: T): T => {
  const config = window.APP_CONFIG || {};

  return get(config, key, valueIfMissing);
};

export const isNotProd = (): boolean => {
  const environment = getConfiguration<string>('environment', '');
  return environment !== 'PROD';
};

export const s4dAdminBase = () =>
  isNotProd() ? 'https://admin.pre.kotipizza.kitchen' : 'https://admin.kotipizza.kitchen';
