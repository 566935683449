import { GridValueFormatterParams } from '@mui/x-data-grid';

export const cellDataGridDateTime = (params: GridValueFormatterParams): string => {
  return dateTimeLocal(params.value as string);
};

export const dateTimeLocal = (isoDate: string | undefined | null): string => {
  if (!isoDate) {
    return 'Invalid date';
  }
  const date = new Date(Date.parse(isoDate));
  return `${date.toLocaleDateString('fi-FI')} ${date.toLocaleTimeString('fi-FI', {
    hour: 'numeric',
    minute: '2-digit',
  })}`;
};
