import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { calculateProductDefaultPrices } from '../../../apis/pricing-api';
import { ProductPriceCalculationRequest, ProductSize } from '@kotipizzagroup/kotipizza-pricing-api-client';
import { useParams } from 'react-router-dom';
import { useUpcomingProducts, useAllIngredients } from '../../../apis/products-api';
import { UpcomingProductSizeDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { PriceCalculationProductData } from '../../ProductsPage/types';
import { TopBar } from '../../ProductsPage';
import { UpcomingProductsCard } from './UpcomingProductCard';

export type RecipeDetails = {
  ingredientName: string;
  ingredientQuantity: number;
  isActive: boolean;
  ingredientId: number;
  dosings: RecipeDosings[];
};

export type RecipeDosings = {
  dosingInGrams: number;
  sizeId: number;
};

type UpcomingProductSizeWithExtraDataDto = UpcomingProductSizeDto & {
  price: number;
  pictureCount: number;
};

export const UpcomingProductDetails: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  const { t } = useTranslation();
  const { data: allIngredients } = useAllIngredients();

  const { data: upcomingProducts } = useUpcomingProducts();

  const [productPrices, setProductPrices] = useState<ProductSize[]>([]);

  useEffect(() => {
    if (!upcomingProducts || !allIngredients) {
      return;
    }

    const productPricePromises =
      upcomingProducts.map((product) => {
        const productData: PriceCalculationProductData = {
          name: product.name ?? '',
          productCategoryId: product.categoryId ?? 0,
          productCategoryName: product.categoryName ?? '',
          productId: product.productId,
          slug: '',
          active: true,
          isSpicy: false,
        };

        const productIngredients =
          product.sizes &&
          product.sizes[0].ingredients?.map((ingredient) => {
            return {
              productId: product.productId,
              ingredientId: ingredient.ingredientId,
              quantity: ingredient.quantity,
              maxQuantity: 3,
              minQuantity: 0,
            };
          });

        return calculateProductDefaultPrices({
          product: { ...productData, ingredients: productIngredients },
          ingredients: allIngredients || [],
          productSizes: product.sizes?.map((size) => ({
            productSizeId: size.sizeId,
            name: size.name,
            displayOrder: 0,
            productCategoryId: product.categoryId,
          })),
        } as ProductPriceCalculationRequest);
      }) ?? [];

    Promise.all(productPricePromises).then((allCalculatedPrices) => {
      const prices = allCalculatedPrices.flat();
      setProductPrices(prices);
    });
  }, [upcomingProducts, allIngredients]);

  const filteredProducts = useMemo(() => {
    if (!upcomingProducts) return [];

    return upcomingProducts.map((product) => {
      const updatedSizeInfo = product.sizes
        ?.map((size) => {
          return {
            ...size,
            pictureCount: countAddedPictures(size),
            price: getProductPrice(product.productId, size.sizeId, productPrices),
          } as UpcomingProductSizeWithExtraDataDto;
        })
        .reverse();
      return { ...product, sizes: updatedSizeInfo };
    });
  }, [upcomingProducts, productPrices]);

  const product = useMemo(() => {
    if (filteredProducts.length < 1) return;

    const productById = filteredProducts.find((p) => p.productId === Number(id));
    if (!productById) return;

    return productById;
  }, [filteredProducts, id]);

  return (
    <>
      <TopBar>
        <Typography variant="h1">{t('nav.upcomingProducts')}</Typography>
      </TopBar>
      <Content>{product && <UpcomingProductsCard product={product} prices={productPrices} />}</Content>
    </>
  );
};

const countAddedPictures = (sizeForProduct: UpcomingProductSizeDto) => {
  let count = 0;
  if (sizeForProduct?.hasDesktopImage) {
    count++;
  }
  if (sizeForProduct?.hasMobileImage) {
    count++;
  }
  if (sizeForProduct?.hasDesktopMenuImage) {
    count++;
  }
  if (sizeForProduct?.hasMobileMenuImage) {
    count++;
  }
  return count;
};

const getProductPrice = (productId?: number, sizeId?: number, prices?: ProductSize[]) => {
  return prices?.find((price) => price.productID === productId && price.productSizeID === sizeId)?.calculatedPrice ?? 0;
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default UpcomingProductDetails;
