import { CardContent, CardHeader, TextField, Typography } from '@mui/material';
import { ChangeEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import { formatDecimal } from '../../../shared/utils/formatters';
import { StyledCard } from './Card.styles';
import { ProductPriceModel, ProductSizeDto } from '@kotipizzagroup/kotipizza-products-api-client';

interface Props {
  productSizeId: ProductSizeDto['productSizeId'];
  title: string;
  isLoadingPrice: boolean;
  calculatedPrice: number;
  price: number;
  priceModel: ProductPriceModel;
  handleChange: (price: number, productSizeId: number) => void;
}

const CalculatedPrice = styled.small`
  display: block;
  margin-bottom: 0.5rem;
`;

const PriceInput = styled(TextField)`
  margin-top: 1rem;
`;

export const ProductPriceCard = (props: Props): JSX.Element => {
  const { title, calculatedPrice, price, isLoadingPrice, priceModel, handleChange, productSizeId } = props;
  const [priceValue, setPriceValue] = useState<string>(String(price));

  const handlePriceChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newPrice = event.target.value.replace(',', '.');
    setPriceValue(String(newPrice));
    const priceNum = parseFloat(newPrice);
    if (!isNaN(priceNum)) {
      handleChange(priceNum, productSizeId);
    }
  };

  // Update price field after prices are loaded.
  useEffect(() => {
    if (!isLoadingPrice) {
      setPriceValue(String(price));
    }
  }, [isLoadingPrice, price]);

  const hasEditablePrice = priceModel === ProductPriceModel.FIXED_MIN_PRICE || priceModel === ProductPriceModel.MONSTER;

  return (
    <StyledCard>
      <CardHeader title={title} />
      <CardContent>
        {isLoadingPrice ? (
          <LoadingIndicator small height="32px" />
        ) : (
          <>
            <CalculatedPrice>laskettu hinta: {formatDecimal(calculatedPrice, ' €')}</CalculatedPrice>
            <Typography variant="h1">{formatDecimal(price, ' €')}</Typography>
            {hasEditablePrice && (
              <PriceInput value={priceValue} type="text" label="Minimihinta" onChange={handlePriceChange} />
            )}
          </>
        )}
      </CardContent>
    </StyledCard>
  );
};
