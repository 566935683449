import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { uniqueByKey } from '../../../shared/utils/arrayUtils';
import { IngredientDosingDialog } from '../../IngredientsPage/components/IngredientDosingDialog';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import {
  DosingDto,
  IngredientCategoryDto,
  ProductIngredientDto,
  ProductSizeDto,
} from '@kotipizzagroup/kotipizza-products-api-client';
import { useAllDosings } from '../../../apis/products-api';

export enum IngredientQuantityChangeType {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}

interface ProductRecipeGridProps {
  productId: number;
  productCategoryId: number;
  productIngredientsRows: ProductIngredientDto[];
  onIngredientQuantityChange: (ingredientId: number, changeType: IngredientQuantityChangeType) => void;
  onMaxQuantityChange: (ingredientId: number, value: string) => void;
  onIngredientRequiredChange: (ingredientId: number, value: boolean) => void;
}

type QuantityActionsProps = {
  ingredientId: number;
  onIngredientQuantityChange: (ingredientId: number, changeType: IngredientQuantityChangeType) => void;
};

const QuantityActions = (props: QuantityActionsProps) => {
  const { onIngredientQuantityChange, ingredientId } = props;
  return (
    <Box>
      <IconButton
        onClick={() => onIngredientQuantityChange(ingredientId, IngredientQuantityChangeType.SUBTRACT)}
        size="small"
        color="error"
      >
        <Remove />
      </IconButton>
      <IconButton
        onClick={() => onIngredientQuantityChange(ingredientId, IngredientQuantityChangeType.ADD)}
        size="small"
        color="success"
      >
        <Add />
      </IconButton>
    </Box>
  );
};

type IngredientDosingProps = {
  ingredientDosings: ProductIngredientDosingRow[];
  productSizeId: number;
  ingredientId: number;
};

const IngredientDosing = (props: IngredientDosingProps) => {
  const { ingredientDosings, ingredientId, productSizeId } = props;

  const dosings = ingredientDosings.find((row) => row.ingredientId === ingredientId)?.dosings;

  const found = dosings?.find((dosing) => dosing.productSizeId === productSizeId);

  if (found === undefined) {
    return (
      <TableCell key={`no_dosing_${ingredientId}`}>
        <Typography fontWeight={700} color="error">
          puuttuu
        </Typography>
      </TableCell>
    );
  }

  return <TableCell key={found.dosingId}>{found.dosingInGrams}g</TableCell>;
};

interface ProductRecipeRow extends ProductIngredientDto {
  ingredientName: string | undefined | null;
  ingredientCategoryName: string | undefined | null;
  isActive: boolean | undefined | null;
}

type ProductIngredientDosingRow = {
  ingredientId: number;
  dosings: DosingDto[] | null | undefined;
};

const StyledLink = styled(Link)`
  font-size: 15px;
`;

export const ProductRecipeGrid = (props: ProductRecipeGridProps) => {
  const {
    productIngredientsRows,
    productCategoryId,
    onIngredientQuantityChange,
    onMaxQuantityChange,
    onIngredientRequiredChange,
  } = props;

  const [ingredientDetailsDialogOpen, setIngredientDetailsDialogOpen] = useState(false);
  const [ingredientDosingDialogId, setIngredientDosingDialogId] = useState(0);

  const { data: dosingsDetails, isLoading: isLoadingDosings, isFetching: isFetchingDosings } = useAllDosings();

  const productIngredientsGridLoading = isLoadingDosings || isFetchingDosings;

  const [productSizes, setProductSizes] = useState<ProductSizeDto[]>([]);

  useEffect(() => {
    setProductSizes(
      uniqueByKey<ProductSizeDto>(
        dosingsDetails?.productSizes?.filter((x: ProductSizeDto) => x.productCategoryId === productCategoryId) || [],
        'productSizeId'
      )
    );
  }, [dosingsDetails, setProductSizes, productCategoryId]);

  const getIngredientName = useCallback(
    (ingredientId: number): string | null | undefined => {
      return dosingsDetails?.ingredients?.find((ingredient) => ingredient.ingredientId === ingredientId)?.name;
    },
    [dosingsDetails?.ingredients]
  );

  const getIngredientCategoryName = useCallback(
    (ingredientId: number): string | null | undefined => {
      const categoryId = dosingsDetails?.ingredients?.find(
        (ingredient) => ingredient.ingredientId === ingredientId
      )?.ingredientCategoryId;
      const category = dosingsDetails?.ingredientCategories?.find(
        (category: IngredientCategoryDto) => category.ingredientCategoryId === categoryId
      );
      return category?.name;
    },
    [dosingsDetails?.ingredientCategories, dosingsDetails?.ingredients]
  );

  const getIngredientActive = useCallback(
    (ingredientId: number): boolean | null | undefined => {
      return dosingsDetails?.ingredients?.find((x) => x.ingredientId === ingredientId)?.isActive;
    },
    [dosingsDetails?.ingredients]
  );

  const convertToRecipeRows = useCallback(
    (ingredients: ProductIngredientDto[]): ProductRecipeRow[] => {
      return ingredients.map((ing) => ({
        ...ing,
        // TODO: ProductIngredientDto ingredientId shouldn't be nullable
        ingredientName: getIngredientName(ing.ingredientId || 0),
        ingredientCategoryName: getIngredientCategoryName(ing.ingredientId || 0),
        isActive: getIngredientActive(ing.ingredientId || 0),
      }));
    },
    [getIngredientActive, getIngredientCategoryName, getIngredientName]
  );

  const [gridRows, setGridRows] = useState<ProductRecipeRow[]>(convertToRecipeRows(productIngredientsRows));
  const [ingredientDosings, setIngredientDosings] = useState<ProductIngredientDosingRow[]>([]);

  useEffect(() => {
    setGridRows(convertToRecipeRows(productIngredientsRows));
  }, [setGridRows, dosingsDetails, productIngredientsRows, convertToRecipeRows]);

  useEffect(() => {
    setIngredientDosings(
      gridRows.map((row: ProductRecipeRow) => {
        const dosings: DosingDto[] = [];

        productSizes.forEach((ps) => {
          const dosing = dosingsDetails?.ingredientDosings?.find(
            (x) => x.ingredientId === row.ingredientId && x.productSizeId === ps.productSizeId
          );

          if (dosing) {
            dosings.push({ ...dosing });
          }
        });

        return {
          ingredientId: row.ingredientId,
          isActive: dosingsDetails?.ingredients?.find((x) => x.ingredientId === row.ingredientId)?.isActive,
          dosings: dosings,
        };
      })
    );
  }, [dosingsDetails?.ingredientDosings, dosingsDetails?.ingredients, gridRows, productSizes]);

  const handleIngredientNameClick = (id: number) => {
    setIngredientDosingDialogId(id);
    setIngredientDetailsDialogOpen(true);
  };

  const theme = useTheme();

  return (
    <Box paddingTop="1em" paddingBottom="1em">
      {!!ingredientDosingDialogId && (
        <IngredientDosingDialog
          title="Ingredient dosings"
          ingredientId={ingredientDosingDialogId as unknown as number}
          open={ingredientDetailsDialogOpen}
          onDialogClose={() => setIngredientDetailsDialogOpen(false)}
        />
      )}
      <TableContainer component={Paper}>
        <LinearProgress sx={{ visibility: productIngredientsGridLoading ? 'visible' : 'hidden' }} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Poista/lisää</TableCell>
              {productSizes.map((size) => (
                <TableCell key={size.productSizeId}>{size.name}</TableCell>
              ))}
              <TableCell>Asiakas saa poistaa</TableCell>
              <TableCell>Max</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gridRows.map((row) => (
              <TableRow
                key={row.ingredientId}
                sx={{ background: row.isActive ? 'inherit' : theme.palette.error.light }}
              >
                <TableCell>
                  <Typography style={{ color: '#009541', fontWeight: 700 }} variant="body2">
                    {row.quantity} x{' '}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box>
                    <StyledLink onClick={() => handleIngredientNameClick(row.ingredientId || 0)}>
                      {row.ingredientName}
                    </StyledLink>
                    <Typography variant="body2">{row.ingredientCategoryName}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <QuantityActions
                    ingredientId={row.ingredientId || 0}
                    onIngredientQuantityChange={onIngredientQuantityChange}
                  />
                </TableCell>
                {productSizes.map((size) => (
                  <IngredientDosing
                    key={size.productSizeId}
                    ingredientDosings={ingredientDosings}
                    productSizeId={size.productSizeId}
                    ingredientId={row.ingredientId}
                  />
                ))}
                <TableCell width="150px">
                  <Switch
                    size="small"
                    onChange={(event) => onIngredientRequiredChange(row.ingredientId || 0, !event.target.checked)}
                    checked={row.minQuantity === 0}
                    value={row.minQuantity === 1 ? 1 : 0}
                  />
                </TableCell>
                <TableCell width="75px">
                  <TextField
                    onChange={(event) => onMaxQuantityChange(row.ingredientId || 0, event.currentTarget.value)}
                    value={row.maxQuantity}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
