import styled from 'styled-components';

const VerticalLine = styled.span`
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 20px;
`;

export const VerticalSeparator = (): JSX.Element => {
  return <VerticalLine />;
};
