import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '@azure/msal-react';
import axios from 'axios';
import { getConfiguration } from '../shared/utils/configuration';
import { getRequestHeaders } from './utils';
import { UserDetailsDTO } from '@kotipizzagroup/kotipizza-products-api-client';
import { getUserDetails } from './products-api';

export type UserRolesResponseData = {
  roles: string[];
};

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const fetchUserPermissionsCampaignsApi = async (): Promise<UserRolesResponseData> => {
  const apiRoot = getConfiguration<string>('campaignsService.root', '');
  const { data } = await axios.get(`${apiRoot}/user/roles`, { headers: await getRequestHeaders() });
  return data;
};

const fetchUserPermissionsLoyaltyApi = async (): Promise<UserRolesResponseData> => {
  const apiRoot = getConfiguration<string>('loyaltyService.root', '');
  const { data } = await axios.get(`${apiRoot}/admin/user/scopes`, { headers: await getRequestHeaders() });
  return data;
};

const fetchUserPermissionsFromShopsApi = async (): Promise<UserRolesResponseData> => {
  const apiRoot = getConfiguration<string>('commerceApi.publicRoot', '');
  const { data } = await axios.get(`${apiRoot}/shops/user/roles`, { headers: await getRequestHeaders() });
  return data;
};

const GetPermissionsQueryOptions = (isAuthenticated: boolean) => {
  return {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: twentyFourHoursInMs,
    retryOnMount: false,
    retry: false,
  };
};

export type UserPermissionsHookResult = {
  roles: string[];
  isErrorCampaigns: boolean;
  isLoadingCampaigns: boolean;
  isErrorLoyalty: boolean;
  isLoadingLoyalty: boolean;
  isLoadingProducts: boolean;
  isErrorProducts: boolean;
  isLoadingShops: boolean;
  isErrorShops: boolean;
  isLoading: boolean;
};

export function useUserPermissions(): UserPermissionsHookResult {
  const isAuthenticated = useIsAuthenticated();

  const queryCampaignsPermissions = useQuery<UserRolesResponseData, Error>(
    ['campaigns.permissions'],
    fetchUserPermissionsCampaignsApi,
    GetPermissionsQueryOptions(isAuthenticated)
  );

  const queryLoyaltyPermissions = useQuery<UserRolesResponseData, Error>(
    ['loyalty.permissions'],
    fetchUserPermissionsLoyaltyApi,
    GetPermissionsQueryOptions(isAuthenticated)
  );

  const queryProductsPermissions = useQuery<UserDetailsDTO, Error>(
    ['products.permissions'],
    getUserDetails,
    GetPermissionsQueryOptions(isAuthenticated)
  );

  const queryShopsPermissions = useQuery<UserRolesResponseData, Error>(
    ['shops.permissions'],
    fetchUserPermissionsFromShopsApi,
    GetPermissionsQueryOptions(isAuthenticated)
  );

  const roles = useMemo(
    () => [
      ...(queryCampaignsPermissions.data?.roles || []),
      ...(queryLoyaltyPermissions.data?.roles || []),
      ...(queryProductsPermissions.data?.roles || []),
      ...(queryShopsPermissions.data?.roles || []),
    ],
    [queryCampaignsPermissions, queryLoyaltyPermissions, queryProductsPermissions, queryShopsPermissions]
  );

  return {
    isLoadingCampaigns: queryCampaignsPermissions.isLoading,
    isErrorCampaigns: queryCampaignsPermissions.isError,
    isLoadingLoyalty: queryLoyaltyPermissions.isLoading,
    isErrorLoyalty: queryLoyaltyPermissions.isError,
    isLoadingProducts: queryProductsPermissions.isLoading,
    isErrorProducts: queryProductsPermissions.isError,
    isLoadingShops: queryShopsPermissions.isLoading,
    isErrorShops: queryShopsPermissions.isError,
    isLoading:
      queryCampaignsPermissions.isLoading ||
      queryLoyaltyPermissions.isLoading ||
      queryProductsPermissions.isLoading ||
      queryShopsPermissions.isLoading,
    roles,
  };
}
