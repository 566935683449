import Axios from 'axios';
import { getConfiguration } from '../shared/utils/configuration';
import {
  PricingClient,
  ProductPriceCalculationRequest,
  ProductSize,
} from '@kotipizzagroup/kotipizza-pricing-api-client';
import { getRequestHeaders } from './utils';

let client: PricingClient | null = null;

const getApiClient = (): PricingClient => {
  if (client) return client;

  const baseUrl = getConfiguration<string>('pricingService.root', '');

  const instance = Axios.create({
    timeout: 120000,
    transformResponse: [],
  });

  instance.interceptors.request.use(async (config) => {
    const headers = await getRequestHeaders();
    config.headers = headers;
    return config;
  });

  // @TODO fix this by updating axios version in api client
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  client = new PricingClient(baseUrl, instance);
  return client;
};

export const calculateProductDefaultPrices = async (
  payload: ProductPriceCalculationRequest
): Promise<ProductSize[]> => {
  const client = getApiClient();
  return client.calculateProductDefaultPrices(payload);
};
