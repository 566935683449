import React from 'react';
import { ButtonProps, Snackbar, SnackbarProps } from '@mui/material';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

export type CustomSnackBarProps = {
  message?: string;
  action?: string;
  ButtonProps?: Partial<ButtonProps>;
  SnackbarProps: Partial<SnackbarProps>;
  customParameters: { autoHideDuration?: number; type: AlertColor };
};

const CustomSnackBar = (props: unknown): JSX.Element => {
  const propsTyped = props as CustomSnackBarProps;
  return (
    <Snackbar {...propsTyped.SnackbarProps} message={propsTyped.message}>
      <MuiAlert severity={propsTyped?.customParameters?.type} elevation={6} variant="filled">
        {propsTyped.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomSnackBar;
