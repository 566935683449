import { useState } from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  responsiveFontSizes,
  styled,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';
import SideDrawer from './components/SideDrawer';
import TopBar from './components/TopBar';

const PREFIX = 'Main';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowWrap: 'anywhere',
    minHeight: '100vh',
    backgroundColor: '#FAFAFA',
    marginTop: theme.spacing(8),
  },
}));

interface LayoutProps {
  children: JSX.Element;
  renderSideDrawer?: boolean;
}

const Main = ({ children }: LayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  let theme = createTheme({
    palette: {
      primary: {
        main: '#009541',
        light: '#33AA67',
        dark: '#007734',
      },
      success: {
        main: '#009541',
        light: '#EFF7EE',
      },
      error: {
        main: '#E31B0C',
        light: '#FDECEA',
        dark: '#FFCFD6',
      },
      warning: {
        main: '#C77700',
        light: '#C77700',
        dark: '#C77700',
      },
      info: {
        main: '#000',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontSize: '28px',
        fontWeight: 'bold',
        transform: 'Sentance',
      },
      h2: {
        fontSize: '21px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '18px',
        fontWeight: 'bold',
      },
      body1: {
        fontSize: '14px',
        fontWeight: 400,
        color: 'black',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#666666',
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&$selected': {
              color: '#009541',
            },
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
    // Dispatch window resize event for MapBox to resize map correctly after drawer's collapsed
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  };

  return (
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Root>
              <CssBaseline />
              <TopBar handleDrawerToggle={handleDrawerToggle} />
              <SideDrawer handleDrawerToggle={handleDrawerToggle} isOpen={drawerOpen} />
              <StyledMain drawerOpen={drawerOpen} className={classes.content}>
                {children}
              </StyledMain>
            </Root>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

const StyledMain = styled('main')<{ drawerOpen: boolean }>`
  transition: 0.1s margin-left;
  margin-left: ${({ drawerOpen }) => (drawerOpen ? '216px' : '0')};
`;

export default Main;
