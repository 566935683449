import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import {
  addOutOfStock,
  allOutOfStockIngredientsCacheKey,
  removeOutOfStock,
  useAllIngredients,
  useAllOutOfStockIngredients,
  useIngredient,
} from '../../apis/products-api';
import { IngredientDto, OutOfStockIngredientDto } from '@kotipizzagroup/kotipizza-products-api-client';
import LoadingOverlay from '../../shared/LoadingOverlay/LoadingOverlay';
import { SearchInput } from '../../shared/Search/SearchInput';
import { useAllRestaurants } from '../../apis/restaurants-service';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';
import { useQueryClient } from '@tanstack/react-query';
import { OutOfStockRow } from '.';
import { BottomBar, TopBar } from '../ProductsPage/ProductDetails';

type OutOfStockIngredientFormData = {
  outOfStocks: OutOfStockIngredientDto[];
};

export const OutOfStockIngredientDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const [ingredientId, setIngredientId] = useState(isNaN(Number(id)) ? 0 : Number(id));
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();
  const snackBar = useCustomSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const isNew = ingredientId === 0;

  const createNewOutOfStockIngredient = (restaurantId: string): OutOfStockIngredientDto => ({
    ingredientId,
    externalShopId: restaurantId,
  });

  const { handleSubmit, control, reset } = useForm<OutOfStockIngredientFormData>({
    defaultValues: {
      outOfStocks: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outOfStocks',
  });

  const appendOutOfStockIngredient = (ingredient: OutOfStockIngredientDto) => {
    if (fields.find((f) => f.externalShopId === ingredient.externalShopId)) {
      snackBar.showError('Ravintola on jo lisätty');
      return;
    }
    append(ingredient);
  };

  const ingredientColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'isActive',
      headerName: t('Status'),
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
  ];

  const restaurantColumns: GridColDef[] = [
    {
      field: 'externalShopId',
      headerName: 'Id',
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      renderCell: (params) => {
        if (!params.row.restaurantName) return null;
        return params.row.restaurantName;
      },
    },
    {
      field: 'remove',
      headerName: t('Unlink'),
      width: 200,
      renderCell: (params) => {
        const index = fields.findIndex((f) => f.externalShopId === params.row.externalShopId);

        return (
          <Button color="error" variant="outlined" onClick={() => remove(index)}>
            {t('Unlink')}
          </Button>
        );
      },
    },
  ];

  const {
    data: ingredient,
    isInitialLoading: isInitialLoadingIngredient,
    isFetching: isFetchingProduct,
  } = useIngredient(ingredientId, { enabled: !isNew });
  const { data: outOfStockIngredients, isInitialLoading: isOutOfStockIngredientsInitialLoading } =
    useAllOutOfStockIngredients();
  const { data: ingredients, isInitialLoading: isInitialLoadingIngredients } = useAllIngredients();
  const { data: restaurants, isInitialLoading: isInitialLoadingRestaurants } = useAllRestaurants();

  const outOfStocks = useMemo(() => {
    return outOfStockIngredients?.filter((i) => i.ingredientId === ingredientId) || [];
  }, [outOfStockIngredients, ingredientId]);

  const restaurantRows = useMemo((): OutOfStockRow[] => {
    return fields.map((f) => ({
      ...f,
      restaurantName: restaurants?.find((r) => r.shopExternalId === f.externalShopId)?.displayName,
    }));
  }, [fields, restaurants]);

  const isInitialLoadComplete =
    !isInitialLoadingIngredient &&
    !isInitialLoadingIngredients &&
    !isOutOfStockIngredientsInitialLoading &&
    !isInitialLoadingRestaurants;

  useEffect(() => {
    if (!isInitialLoadComplete) return;
    reset({ outOfStocks: outOfStockIngredients?.filter((i) => i.ingredientId == ingredientId) });
  }, [isInitialLoadComplete, outOfStockIngredients, ingredientId, reset]);

  const isSaveDisabled = isNew || isFetchingProduct;

  const handleSave: SubmitHandler<OutOfStockIngredientFormData> = async (formData) => {
    try {
      setIsSaving(true);

      for (const item of formData.outOfStocks) {
        // Find and post new items
        const itemExists = outOfStocks?.find((p) => p.externalShopId === item.externalShopId);
        if (!itemExists && item.ingredientId && item.externalShopId) {
          await addOutOfStock({ ingredientId: item.ingredientId, externalShopId: item.externalShopId });
        }
      }

      // Remove items
      for (const item of outOfStocks) {
        const shouldKeep = formData.outOfStocks.find((i) => i.externalShopId === item.externalShopId);
        if (!shouldKeep && item.ingredientId && item.externalShopId) {
          await removeOutOfStock({ ingredientId: item.ingredientId, externalShopId: item.externalShopId });
        }
      }

      setIsSaving(false);
      await queryClient.invalidateQueries([allOutOfStockIngredientsCacheKey]);
      snackBar.showSuccess(t('global.saveSucceeded'));
      navigate('/out-of-stock');
    } catch (e) {
      snackBar.showError(t('global.saveFailed'));
      setIsSaving(false);
    }
  };

  const SaveButton = () => {
    return (
      <LoadingButton
        onClick={handleSubmit(handleSave)}
        startIcon={<Save />}
        loading={isSaving}
        disabled={isSaveDisabled}
        variant="contained"
        color="primary"
      >
        {t('global.save')}
      </LoadingButton>
    );
  };

  const handleAddRestaurant = (value: string) => {
    appendOutOfStockIngredient(createNewOutOfStockIngredient(value));
  };

  const handleSelectIngredient = (value: string) => {
    setIngredientId(Number(value));
  };

  const ingredientList: IngredientDto[] = useMemo(() => {
    if (!ingredient) return [];
    return [{ ...ingredient }];
  }, [ingredient]);

  return (
    <Box>
      {!isInitialLoadComplete && <LoadingOverlay />}
      <TopBar>
        <Typography variant="h1">
          {t('Out of stock products')} / {t('Mark as out of stock')}
        </Typography>
        <SaveButton />
      </TopBar>
      <Grid container item justifyContent="space-between" alignItems="center" my={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h2">{t('Out of stock ingredient')}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {isNew && (
            <SearchInput
              values={ingredients?.map((p) => ({ value: String(p.ingredientId), label: p.name })) || []}
              noLabelPrefix={true}
              onSelect={handleSelectIngredient}
              label={t('Select ingredient')}
            />
          )}
        </Grid>
      </Grid>
      {ingredient && (
        <StyledDataGrid
          disableRowSelectionOnClick
          rows={ingredientList}
          columns={ingredientColumns}
          getRowId={(row) => row.ingredientId}
          loading={false}
          hideFooter={true}
          getRowClassName={(params) => (params.row.active ? '' : '')}
        />
      )}

      {!isNew && (
        <div>
          <Grid container item justifyContent="space-between" alignItems="center" my={2} marginTop={6}>
            <Grid item xs={12} md={4}>
              <Typography variant="h2">{t('Out of stock from restaurant')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <SearchInput
                values={restaurants?.map((r) => ({ value: r.shopExternalId, label: r.displayName })) || []}
                onSelect={handleAddRestaurant}
                noLabelPrefix={true}
                label={t('Search restaurant')}
              />
            </Grid>
          </Grid>
          <StyledDataGrid
            disableRowSelectionOnClick
            rows={restaurantRows}
            columns={restaurantColumns}
            getRowId={(row) => row.externalShopId}
            loading={false}
            getRowClassName={(params) => (params.row.active ? '' : '')}
          />
        </div>
      )}

      <Grid item xs={12} container marginTop={8}>
        <SaveButton />
      </Grid>
      <BottomBar />
    </Box>
  );
};
