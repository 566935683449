import { AlertColor } from '@mui/material';
import { useSnackbar } from 'material-ui-snackbar-provider';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCustomSnackBar = () => {
  const snackBar = useSnackbar();

  return React.useMemo(() => {
    const showMessage =
      (type: AlertColor) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (message: string, action?: string, handleAction?: () => void, customParameters?: any) =>
        snackBar.showMessage(message, action, handleAction, {
          ...customParameters,
          type,
        });
    return {
      ...snackBar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    };
  }, [snackBar]);
};
