import React, { createContext, ReactNode, useState } from 'react';

interface Context {
  open: boolean;
  texts: Texts;
  handlers: Handlers;
  setOpen: (open: boolean) => void;
  setTexts: (
    title: string,
    description: ReactNode,
    cancelText: string,
    confirmText: string,
    loadingText: string
  ) => void;
  setHandlers: (onConfirm: () => Promise<unknown>, onCancel: () => Promise<unknown>) => void;
}

interface Texts {
  title: string;
  description: ReactNode;
  cancelText: string;
  confirmText: string;
  loadingText: string;
}

interface Handlers {
  onConfirm: () => Promise<unknown>;
  onCancel: () => Promise<unknown>;
}

const defaultHandlers = {
  onConfirm: async () => undefined,
  onCancel: async () => undefined,
};

const defaultTexts = {
  title: '',
  description: '',
  cancelText: '',
  confirmText: '',
  loadingText: '',
};

const contextDefaultValues: Context = {
  open: false,
  texts: defaultTexts,
  handlers: defaultHandlers,
  setOpen: () => undefined,
  setTexts: () => undefined,
  setHandlers: () => undefined,
};

export const ConfirmContext = createContext<Context>(contextDefaultValues);

export const ConfirmContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [texts, setTexts] = useState<Texts>(defaultTexts);
  const [handlers, setHandlers] = useState<Handlers>(defaultHandlers);

  return (
    <ConfirmContext.Provider
      value={{
        open,
        texts,
        handlers,
        setOpen,
        setTexts: (title, description, cancelText, confirmText, loadingText) =>
          setTexts({ title, description, cancelText, confirmText, loadingText }),
        setHandlers: (onConfirm, onCancel) => setHandlers({ onConfirm, onCancel }),
      }}
    >
      {children}
    </ConfirmContext.Provider>
  );
};
