import React, { Suspense } from 'react';
import './index.css';
import './i18n/config';
import App from './app/App';
import './firstOrDefault';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { authProvider } from './authProvider';
import { MsalProvider } from '@azure/msal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import LoadingIndicator from './app/shared/LoadingIndicator';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import CustomSnackBar from './app/shared/SnackBar/CustomSnackBar';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { getConfiguration, isNotProd } from './app/shared/utils/configuration';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    },
  },
});

const InitApp = (Component: React.FC) => {
  fetch('/app-config.json')
    .then((response) => response.json())
    .then((config) => {
      RenderApp(Component, config);
    });
};

const RenderApp = (Component: React.FC, config: typeof window.APP_CONFIG) => {
  window.APP_CONFIG = config;

  const msalInstance = authProvider.getMsalAuthProvider();

  const favicon = getConfiguration<string>('head.favicon', '/favicon.ico');
  const environment = getConfiguration<string>('environment', '');
  const isDevOrQa = isNotProd();

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  return root.render(
    <Suspense fallback={<LoadingIndicator message="Ladataan..." />}>
      <React.StrictMode>
        <SnackbarProvider
          SnackbarComponent={CustomSnackBar}
          SnackbarProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: 3000,
          }}
        >
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <Helmet>
                <title>Kotipizza Juuri{isDevOrQa ? ` - ${environment}` : ''}</title>
                <link rel="icon" href={favicon} />
              </Helmet>
              <Component />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </MsalProvider>
        </SnackbarProvider>
      </React.StrictMode>
    </Suspense>
  );
};

InitApp(App);
