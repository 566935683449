import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAllProductCategories, useAllS4DProducts, useProduct } from '../../../apis/products-api';
import { useProductRestoMapping } from '../../../apis/restoIntegration-service';
import { useTranslation } from 'react-i18next';
import { s4dAdminBase } from '../../../shared/utils/configuration';
import { CheckCircle, Error } from '@mui/icons-material';

type Props = {
  productId: number;
};

const ProductLinks = (props: Props) => {
  const { productId } = props;
  const { t } = useTranslation();
  const isNewProduct = productId === 0;

  const {
    data: product,
    isInitialLoading: isInitialLoadingProduct,
    isFetching: isFetchingProduct,
  } = useProduct(productId, { enabled: !isNewProduct });

  const {
    data: s4dProducts,
    isInitialLoading: isInitialLoadingS4DProducts,
    isFetching: isFetchingS4DProducts,
  } = useAllS4DProducts();
  const {
    data: restoProducts,
    isInitialLoading: isInitialLoadingResto,
    isFetching: isFetchingResto,
  } = useProductRestoMapping(productId, !isNewProduct);

  const {
    data: productCategories,
    isInitialLoading: isInitialLoadingCategories,
    isFetching: isFetchingCategories,
  } = useAllProductCategories();

  if (
    isInitialLoadingResto ||
    isInitialLoadingS4DProducts ||
    isFetchingResto ||
    isFetchingS4DProducts ||
    isInitialLoadingCategories ||
    isFetchingCategories ||
    isInitialLoadingProduct ||
    isFetchingProduct
  ) {
    return null;
  }

  const category = productCategories?.find((c) => c.productCategoryId === product?.productCategoryId);
  const s4dProductList = s4dProducts?.filter((p) => p.productId == productId) || [];

  const getRestoId = (sizeId: number): string | null => {
    const restoProduct = restoProducts?.find((rp) => rp.productId === productId && rp.sizeId === sizeId);
    return restoProduct?.restoArticleId ?? null;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{t('global.status')}</TableCell>
            <TableCell>{t('global.id')}</TableCell>
            <TableCell>{t('global.link')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {category?.productSizes.map((size) => (
            <TableRow key={size.productSizeId}>
              <TableCell>Resto / {size.name}</TableCell>
              <TableCell>
                {getRestoId(size.productSizeId) ? <CheckCircle color="success" /> : <Error color="error" />}
              </TableCell>
              <TableCell>
                {getRestoId(size.productSizeId) ? getRestoId(size.productSizeId) : t('product.restoMapping.missing')}
              </TableCell>
              <TableCell>
                <Link href="https://web.restolution.fi/main/articles">Resto</Link>
              </TableCell>
            </TableRow>
          ))}
          {s4dProductList.map((p) => (
            <TableRow key={p.productId}>
              <TableCell>S4D</TableCell>
              <TableCell>
                <CheckCircle color="success" />
              </TableCell>
              <TableCell>{p.s4dId}</TableCell>
              <TableCell>
                <Link href={`${s4dAdminBase()}/Content/Secure/Menu/Products/Details.aspx?product=${p.s4dId}`}>
                  S4D Admin
                </Link>
              </TableCell>
            </TableRow>
          ))}
          {s4dProductList.length === 0 && (
            <TableRow>
              <TableCell>S4D</TableCell>
              <TableCell>
                <Error color="error" />
              </TableCell>
              <TableCell>{t('product.restoMapping.missing')}</TableCell>
              <TableCell>
                <Link href={`${s4dAdminBase()}/Content/Secure/Menu/Products/Overview.aspx`}>S4D Admin</Link>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductLinks;
