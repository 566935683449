import { useState } from 'react';
import { Button, Chip, Typography } from '@mui/material';
import { TopBar } from '../ProductsPage';
import { HasAllPermissions, SHOPS_WRITE } from '../../shared/utils/permissions';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '../../apis/user-details';
import { DataGridContainer } from '../RestaurantsPage/tabs/Info';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditExceptionDateDialog from './EditExceptionDateDialog';
import dayjs from 'dayjs';
import { removeExceptionDate, shopsApiQueryKeys, useAllExceptionDates } from '../../apis/restaurants-service';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';

export enum deliveryPricing {
  Normal = 'normal',
  Low = 'lowered',
}

export type ExceptionDate = {
  id?: number;
  date: string;
  name: string;
  localName?: string;
  isLunchOffer: boolean;
  preorderDeliveryPricing: deliveryPricing;
};

type Row = ExceptionDate & {
  id: string;
};

const ExceptionDatePage: React.FC = () => {
  const [editExceptionDateDialogOpen, setEditExceptionDateDialogOpen] = useState(false);
  const [selected, setSelected] = useState<ExceptionDate | undefined>(undefined);
  const { data: exceptionDates } = useAllExceptionDates();

  const { t } = useTranslation();
  const { roles: userPermissions } = useUserPermissions();
  const queryClient = useQueryClient();
  const snackBar = useCustomSnackBar();

  const handleNewExceptionDate = () => {
    setSelected(undefined);
    setEditExceptionDateDialogOpen(true);
  };

  const handleRemoveClick = async (date: ExceptionDate) => {
    if (!date.id) return;

    await removeExceptionDate(date.id);
    await queryClient.invalidateQueries([shopsApiQueryKeys.allExceptionDates]);
    snackBar.showSuccess(t('exceptionDate.snackbar.deleted'));
  };

  const columns: GridColDef<Row>[] = [
    {
      field: 'date',
      headerName: t('exceptionDate.list.date'),
      flex: 2,
      renderCell(params) {
        return (
          <Button
            onClick={() => {
              setSelected(params.row);
              setEditExceptionDateDialogOpen(true);
            }}
          >
            {dayjs(params.row.date).format('D.M.YYYY')}
          </Button>
        );
      },
    },
    {
      field: 'name',
      headerName: t('exceptionDate.list.localName'),
      flex: 1,
    },
    {
      field: 'isLunchOffer',
      headerName: t('exceptionDate.list.isLunchOffer'),
      flex: 1,
      renderCell: (params) => {
        return (params.value as boolean) ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
    {
      field: 'preorderDeliveryPricing',
      headerName: t('exceptionDate.list.deliveryPricing'),
      flex: 1,
      renderCell: (params) => t(`exceptionDate.deliveryPricing.${params.value}`),
    },
    {
      field: 'remove',
      headerName: '',
      align: 'right',
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => handleRemoveClick(params.row)} color="error">
          {t('global.delete')}
        </Button>
      ),
    },
  ];

  return (
    <>
      <TopBar>
        <Typography variant="h1">{t('exceptionDate.incomingHolidays')}</Typography>
        <Button
          disabled={!HasAllPermissions(userPermissions || [], [SHOPS_WRITE])}
          variant="contained"
          onClick={handleNewExceptionDate}
          color="primary"
        >
          {t('exceptionDate.addExceptionDate')}
        </Button>
      </TopBar>
      {!exceptionDates ? (
        <p>{t('global.loading')}</p>
      ) : (
        <DataGridContainer>
          <DataGrid
            columns={columns}
            rows={exceptionDates.map(
              (r) =>
                ({
                  ...r,
                  id: r.id?.toString() || r.date,
                } as Row)
            )}
          />
        </DataGridContainer>
      )}
      <EditExceptionDateDialog
        open={editExceptionDateDialogOpen}
        onDialogClose={() => setEditExceptionDateDialogOpen(false)}
        exceptionDate={selected}
      />
    </>
  );
};

export default ExceptionDatePage;
