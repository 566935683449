import { Add, Search } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Grid, IconButton } from '@mui/material';

type Option = {
  label: string;
  value: string;
};

type Props = {
  onSelect: (value: string) => void;
  values: Option[];
  label: string;
  noLabelPrefix?: boolean;
};

export const SearchInput: React.FC<Props> = ({ onSelect, values, label, noLabelPrefix, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (_: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleChange = (value: any) => {
    onSelect && onSelect(value.value || 0);
  };

  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();
  const highlightOptions = {
    insideWords: true,
  };

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Autocomplete
      {...props}
      freeSolo
      disableClearable
      autoHighlight
      blurOnSelect
      openOnFocus={false}
      inputValue={inputValue}
      onChange={(_, value) => handleChange(value)}
      onInputChange={handleInputChange}
      options={values || []}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option as Option).label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: <Search />,
          }}
          label={noLabelPrefix ? label : `Etsi lisättävä ${label}`}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match((option as Option).label || '', inputValue, highlightOptions);
        const parts = parse((option as Option).label || '', matches);

        return (
          <li {...props} key={(option as Option).value}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid container item xs={11} direction="column">
                <Grid item xs={11}>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton color="primary">
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
