import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

interface CustomTextFieldInputProps<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError | undefined;
  label: string;
  isDirty?: boolean;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string;
  inputAdornmentText?: string;
}

export const CustomTextFieldInput = <T extends FieldValues>({
  name,
  defaultValue,
  label,
  control,
  error,
  textFieldProps,
  disabled,
  readOnly = false,
  helperText,
  inputAdornmentText,
  ...rest
}: CustomTextFieldInputProps<T>): JSX.Element => {
  return (
    <Controller
      {...rest}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          FormHelperTextProps={{ style: formHelperTextStyles }}
          inputRef={ref}
          InputLabelProps={{ shrink: !!value || value == 0 }}
          error={!!error}
          helperText={
            <HelperTexts>
              {helperText}
              {error?.message && <span>🚨 {error.message}</span>}
            </HelperTexts>
          }
          label={label}
          {...textFieldProps}
          variant="outlined"
          value={value ?? ''}
          style={textFieldStyles}
          onChange={(e) => (textFieldProps?.type === 'number' ? onChange(+e.target.value) : onChange(e.target.value))}
          disabled={disabled ? true : false}
          InputProps={{
            readOnly,
            style: textFieldInputPropsStyles,
            endAdornment: inputAdornmentText && <InputAdornment position="end">{inputAdornmentText}</InputAdornment>,
          }}
        />
      )}
    />
  );
};

const HelperTexts = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    color: red;
    font-weight: bold;
  }
`;

const formHelperTextStyles = {
  backgroundColor: 'transparent',
  margin: 0,
  marginTop: 8,
  color: 'black',
};

const textFieldInputPropsStyles = { backgroundColor: 'white' };

const textFieldStyles = { backgroundColor: 'transparent' };
