import { AppBar, Toolbar, Box, IconButton, Button, useMediaQuery } from '@mui/material';
import { VerticalSeparator } from '../../../shared/VerticalSeparator';
import { TopBarProfile } from './TopBarProfile';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { authProvider } from '../../../../authProvider';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocalStorage } from 'usehooks-ts';
import { getConfiguration, isNotProd } from '../../../shared/utils/configuration';
import { Link } from 'react-router-dom';

type SupportedLanguage = {
  nativeName: string;
};

type SupportedLanguages = {
  en: SupportedLanguage;
  fi: SupportedLanguage;
  [key: string]: SupportedLanguage;
};

const Logo = styled.img`
  height: 42px;
`;

const ToolbarContent = styled.div`
  width: 100%;
  display: flex;
`;

const PREFIX = 'TopBar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
};

const StyledTopBar = styled.div<{ $isDevOrQa: boolean }>(({ theme, $isDevOrQa }) => ({
  [`& .${classes.appBar}`]: {
    color: 'white',
    backgroundColor: $isDevOrQa ? '#9c27b0' : '#009541',
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
}));

const EnvironmentTitle = styled.h2`
  margin-left: 20px;
`;

interface TopBarProps {
  handleDrawerToggle: () => void;
}

const TopBar = ({ handleDrawerToggle }: TopBarProps): JSX.Element => {
  const { i18n } = useTranslation();
  const accountInfo = authProvider.getAccountInfo();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const environment = getConfiguration<string>('environment', '');
  const isDevOrQa = isNotProd();

  const [langPreferance, setLangPreferance] = useLocalStorage<string>('userPreferance.lang', 'fi');

  useEffect(() => {
    i18n.changeLanguage(langPreferance || 'fi');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langPreferance]);

  const supportedLanguages: SupportedLanguages = {
    en: { nativeName: 'English' },
    fi: { nativeName: 'Suomi' },
  };

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    setLangPreferance(lng);
  };

  const logout = () => {
    authProvider.logout();
  };

  return (
    <StyledTopBar $isDevOrQa={isDevOrQa}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <ToolbarContent>
            <Box alignItems="center" display="flex">
              <IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                onClick={handleDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box alignItems="center" display="flex" flex="1">
              <Link to="/">
                <Logo src="https://www.kotipizza.fi/dist/images/icons/logo_kp.svg" />
              </Link>
              {isDevOrQa && <EnvironmentTitle>{environment}</EnvironmentTitle>}
            </Box>

            <Box display="flex">
              {!isMobile &&
                Object.keys(supportedLanguages).map((lng) => (
                  <Button
                    key={lng}
                    disableElevation
                    variant="contained"
                    color={isDevOrQa ? 'secondary' : 'primary'}
                    style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}
                    type="submit"
                    onClick={() => handleLanguageChange(lng)}
                  >
                    {supportedLanguages[lng].nativeName}
                  </Button>
                ))}
              <VerticalSeparator />
              <TopBarProfile email={accountInfo?.username} fullName={accountInfo?.name} onLogout={logout} />
            </Box>
          </ToolbarContent>
        </Toolbar>
      </AppBar>
    </StyledTopBar>
  );
};

export default TopBar;
