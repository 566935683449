import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, Route, Routes } from 'react-router-dom';
import { useAllProducts } from '../../apis/products-api';
import dayjs from 'dayjs';
import { TopBar } from '../ProductsPage';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { UpcomingProductDetails } from './UpcomingProduct/UpcomingProductDetails';

type ProductDate = {
  productId: number;
  productName: string;
  type: 'activation' | 'deactivation';
  date: dayjs.Dayjs;
};

export const ProductTimelinePage: React.FC = () => {
  const { data: products, isLoading } = useAllProducts();

  const { t } = useTranslation();

  const productDates: ProductDate[] = useMemo(() => {
    if (!products) return [];

    const now = dayjs();
    let dates: ProductDate[] = [];
    dates = products.reduce((prev, product) => {
      if (!product.productId) return prev;

      const productInfo = {
        productId: product.productId,
        productName: product.name,
      };

      if (product.startDate && now <= dayjs(product.startDate)) {
        prev.push({ ...productInfo, date: dayjs(product.startDate), type: 'activation' });
      }
      if (product.endDate && now <= dayjs(product.endDate)) {
        prev.push({ ...productInfo, date: dayjs(product.endDate), type: 'deactivation' });
      }

      return prev;
    }, [] as ProductDate[]);

    const sortedDates = dates.sort((dateA, dateB) => (dateA.date.isBefore(dateB.date) ? -1 : 1));

    return sortedDates;
  }, [products]);

  return (
    <Routes>
      <Route path=":id" element={<UpcomingProductDetails />} />
      <Route
        index
        element={
          <>
            <TopBar>
              <Typography variant="h1">{t('nav.upcomingProducts')}</Typography>
            </TopBar>
            <Content>
              <StyledTimeline>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="info" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{dayjs().format('DD.MM.YYYY')}</TimelineContent>
                </TimelineItem>
                {isLoading ? (
                  <TimelineLoading />
                ) : (
                  productDates.map((date) => {
                    const isActivation = date.type === 'activation';

                    return (
                      <Link
                        to={isActivation ? `/upcoming-products/${date.productId}` : `/products/${date.productId}`}
                        key={`${date.productId}-${isActivation ? 'activation' : 'removal'}`}
                      >
                        <StyledTimelineItem position={isActivation ? 'right' : 'left'}>
                          <TimelineSeparator>
                            <TimelineDot color={isActivation ? 'success' : 'error'} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            {date.date.format('DD.MM.YYYY')} {isActivation ? t('upcoming') : t('deactivating')}
                            <br />
                            {date.productName}
                          </TimelineContent>
                        </StyledTimelineItem>
                      </Link>
                    );
                  })
                )}
              </StyledTimeline>
            </Content>
          </>
        }
      />
    </Routes>
  );
};

const TimelineLoading: React.FC = () => (
  <Box sx={{ width: '25rem', display: 'flex', flexDirection: 'column', gap: '16px' }}>
    <Skeleton sx={{ width: '50%', alignSelf: 'flex-end' }} />
    <Skeleton sx={{ width: '50%', alignSelf: 'flex-end' }} />
    <Skeleton sx={{ width: '50%', alignSelf: 'flex-start' }} />
    <Skeleton sx={{ width: '50%', alignSelf: 'flex-end' }} />
  </Box>
);

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const StyledTimeline = styled(Timeline)`
  max-width: 25rem;
`;

const StyledTimelineItem = styled(TimelineItem)`
  cursor: pointer;

  &:hover {
    & > * {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export default ProductTimelinePage;
