import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Restaurant } from '../../../../../types/Restaurant';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { restaurantPageState } from '../state';
import { useState } from 'react';

type Props = {
  restaurants: Restaurant[];
};

type Row = {
  id: string;
  displayName: string;
  isActive: boolean;
};

const Info: React.FC<Props> = (props) => {
  const { restaurants } = props;
  const state = restaurantPageState.getState();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: state.tabs.info.page,
  });
  const { t } = useTranslation();

  const columns: GridColDef<Row>[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'isActive',
      headerName: 'Aktiivinen',
      width: 100,
      renderCell(params) {
        return params.row.isActive ? <CheckCircle color="success" /> : <Cancel color="disabled" />;
      },
    },
    {
      field: 'displayName',
      headerName: t('restaurants.list.name'),
      flex: 2,
      renderCell(params) {
        return <Link to={params.row.id}>{params.row.displayName}</Link>;
      },
    },
  ];

  const rows: Row[] = restaurants.map((r) => ({
    id: r.shopExternalId,
    displayName: r.displayName,
    isActive: r.isActive,
  }));

  const handlePagination = (model: GridPaginationModel) => {
    setPaginationModel(model);

    restaurantPageState.setState({
      tabs: {
        ...state.tabs,
        info: {
          ...state.tabs.info,
          page: model.page,
        },
      },
    });
  };

  return (
    <Box>
      <Typography variant="h2">{t('restaurants.list.info')}</Typography>
      <DataGridContainer>
        <DataGrid
          columns={columns}
          rows={rows}
          initialState={{ sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePagination}
        />
      </DataGridContainer>
    </Box>
  );
};

export const DataGridContainer = styled(Box)`
  height: calc(100vh - 400px);
`;

export default Info;
