import { Avatar, Grid, GridProps, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

export type TopBarProfileProps = {
  fullName?: string;
  email?: string;
  onLogout: () => void;
};

interface UserDetailsProps extends GridProps {
  $isMobile: boolean;
}

const UserDetails = styled(Grid)`
  display: ${({ $isMobile }: UserDetailsProps) => ($isMobile ? 'none' : '')};
`;

const whiteText: React.CSSProperties = { color: 'white' };

export const TopBarProfile = ({ fullName, email, onLogout }: TopBarProfileProps): ReactElement | null => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getAvatarInitials = useMemo(() => {
    if (!fullName) return '';
    const delimeter = fullName.indexOf(' ') !== -1 ? ' ' : '.'; //fullname format can be firstname.lastname or Firstname Lastname
    const splitted = fullName.toUpperCase().split(delimeter);
    return `${splitted[0].charAt(0)}${splitted[1]?.charAt(0)}`;
  }, [fullName]);

  if (!fullName || !email) return null;

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item>
        <Avatar>{getAvatarInitials}</Avatar>
      </Grid>
      <UserDetails $isMobile={isMobile} item>
        <Typography variant="subtitle1">{fullName}</Typography>
        <Typography variant="body2" style={whiteText}>
          {email}
        </Typography>
      </UserDetails>
      <Grid item>
        <IconButton onClick={onLogout} aria-label="logout" size="large">
          <ExitToApp style={whiteText}></ExitToApp>
        </IconButton>
      </Grid>
    </Grid>
  );
};
