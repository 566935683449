import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

export interface FormRadioProps<T extends FieldValues> extends UseControllerProps<T> {
  label?: React.ReactNode;
  value: string;
}

export const FormRadio = <T extends FieldValues>(props: FormRadioProps<T>): JSX.Element => {
  const { label, value } = props;

  return (
    <Controller
      {...props}
      render={({ field: { ref, onChange, value: fieldValue } }) => (
        <FormControlLabel
          label={label}
          value={value}
          onChange={onChange}
          control={<Radio checked={fieldValue === value} inputRef={ref} />}
          labelPlacement="end"
        />
      )}
    />
  );
};
