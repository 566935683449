import React from 'react';
import { Switch } from '@mui/material';
import { withTheme } from '@mui/styles';
import { isUndefined } from 'lodash';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

const ControlContainer = styled.div`
  margin-bottom: 8px;
`;

const LabelBlock = withTheme(styled.label<{ $isActive?: boolean; $isError?: boolean }>`
  display: block;
  color: ${(props) =>
    props.$isError
      ? props.theme.palette.error.main
      : props.$isActive
      ? props.theme.palette.primary.main
      : props.theme.palette.text.primary};
`);

export interface CustomFormSwitch<T extends FieldValues> extends UseControllerProps<T> {
  label?: React.ReactNode;
  uncheckedLabel?: React.ReactNode;
  isError?: boolean;
  disabled?: boolean;
}

const isChecked = (value: never): boolean => {
  // why? https://github.com/react-hook-form/react-hook-form/issues/459#issuecomment-549664020
  return isUndefined(value) || value === '' ? true : value;
};

export const FormSwitch = <T extends FieldValues>(props: CustomFormSwitch<T>): JSX.Element => {
  const { label, uncheckedLabel, control, name, isError, disabled } = props;

  return (
    <ControlContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ref, onChange } }) => (
          <>
            <Switch
              inputRef={ref}
              checked={isChecked(value)}
              color="primary"
              disabled={isError || disabled}
              onChange={onChange}
            />
            <LabelBlock $isActive={isChecked(value)} $isError={isError}>
              {isChecked(value) ? label : uncheckedLabel ?? label}
            </LabelBlock>
          </>
        )}
      />
    </ControlContainer>
  );
};
