import axios, { AxiosInstance } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getConfiguration } from '../shared/utils/configuration';
import { getRequestHeaders } from './utils';
import { ApiRestaurant, BackofficeRestaurant, Restaurant, RestaurantInformation } from '../../../types/Restaurant';
import { ExceptionDate } from '../views/ExceptionDatePage';
import dayjs from 'dayjs';
import { DatePickerFormat } from '../views/CampaignsPage/shared';

let publicInstance: AxiosInstance | null = null;
let privateInstance: AxiosInstance | null = null;

const getApiClient = (privateApi = false): AxiosInstance => {
  if (publicInstance && !privateApi) return publicInstance;
  if (privateInstance && privateApi) return privateInstance;

  const baseUrl = privateApi
    ? getConfiguration<string>('commerceApi.root', '')
    : getConfiguration<string>('commerceApi.publicRoot', '');

  const client = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
  });

  client.interceptors.request.use(async (config) => {
    config.headers = await getRequestHeaders();
    return config;
  });

  if (privateApi) {
    privateInstance = client;
  } else {
    publicInstance = client;
  }

  return client;
};

export const shopsApiQueryKeys = {
  allRestaurants: 'restaurants',
  singleBackofficeRestaurant: 'backoffice-restaurant',
  allBackofficeRestaurants: 'all-backoffice-restaurant',
  allExceptionDates: 'exception-dates',
};

const fetchRestaurants = async (): Promise<Restaurant[]> => {
  const client = getApiClient();
  const { data } = await client.get<ApiRestaurant[]>('/shops/all');

  const restaurants: Restaurant[] = data.map((r) => ({
    ...r,
    longitude: parseFloat(r.longitude),
    latitude: parseFloat(r.latitude),
  }));

  restaurants.sort((a, b) => a.displayName.localeCompare(b.displayName));

  return restaurants;
};

const fetchBackofficeRestaurant = async (shopExternalId: string): Promise<BackofficeRestaurant> => {
  const client = getApiClient();
  const { data } = await client.get<BackofficeRestaurant>(`/shops/backoffice/${shopExternalId}`);
  return data;
};

const fetchBackofficeRestaurants = async (): Promise<RestaurantInformation[]> => {
  const client = getApiClient();
  const { data } = await client.get<RestaurantInformation[]>('/shops/backoffice/');
  return data;
};

/**
 * Exception dates
 */
export const upsertExceptionDate = async (exceptionDate: ExceptionDate): Promise<void> => {
  const client = getApiClient();

  if (!exceptionDate.id) {
    await client.post<ExceptionDate>('/exceptiondates', exceptionDate);
  } else {
    await client.put<ExceptionDate>('/exceptiondates', exceptionDate);
  }
};

const getAllExceptionDates = async (): Promise<ExceptionDate[]> => {
  const client = getApiClient();

  const { data } = await client.get<ExceptionDate[]>('/exceptiondates/all');
  return data.map((exceptionDate) => ({
    ...exceptionDate,
    date: dayjs(exceptionDate.date).format(DatePickerFormat),
  }));
};

export const removeExceptionDate = async (id: number): Promise<void> => {
  const client = getApiClient();
  await client.delete<ExceptionDate[]>(`/exceptiondates/${id}`);
};

type Options<T> = Parameters<typeof useQuery<T>>['2'];

const getDefaultOptions = <T,>(): Options<T> => ({
  enabled: true,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const setPaymentsApiStatus = async ({
  shopId,
  paymentsApiStatus,
}: {
  shopId: number;
  shopExternalId: string;
  paymentsApiStatus: string;
}) => {
  const client = getApiClient();
  await client.put(`/shops/${shopId}/paymentsApi`, { paymentsApiStatus });
  return true;
};

export const useAllRestaurants = (options = getDefaultOptions<Restaurant[]>()): UseQueryResult<Restaurant[], unknown> =>
  useQuery<Restaurant[]>([shopsApiQueryKeys.allRestaurants], fetchRestaurants, options);

export const useBackofficeRestaurant = (
  shopExternalId: string,
  options = getDefaultOptions<BackofficeRestaurant>()
): UseQueryResult<BackofficeRestaurant, unknown> =>
  useQuery<BackofficeRestaurant>(
    [shopsApiQueryKeys.singleBackofficeRestaurant, shopExternalId],
    () => fetchBackofficeRestaurant(shopExternalId),
    options
  );

export const useAllBackofficeRestaurants = (
  options = getDefaultOptions<RestaurantInformation[]>()
): UseQueryResult<RestaurantInformation[], unknown> =>
  useQuery<RestaurantInformation[]>([shopsApiQueryKeys.allBackofficeRestaurants], fetchBackofficeRestaurants, options);

export const useAllExceptionDates = (
  options = getDefaultOptions<ExceptionDate[]>()
): UseQueryResult<ExceptionDate[], unknown> =>
  useQuery<ExceptionDate[]>([shopsApiQueryKeys.allExceptionDates], getAllExceptionDates, options);
