import React from 'react';
import styled from 'styled-components';

const LoadingIndicator: React.FC<{ message?: string; height?: string; small?: boolean }> = ({
  message,
  height,
  small,
}) => {
  if (!height) {
    height = '100vh';
  }
  return (
    <CenterScreen height={height}>
      <div>
        <StyledSpinner small={small}>
          {small ? (
            <circle className="path" cx="12" cy="12" r="10" fill="none" strokeWidth="2" />
          ) : (
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
          )}
        </StyledSpinner>
        {message && <LoadingMessage>{message}</LoadingMessage>}
      </div>
    </CenterScreen>
  );
};

const LoadingMessage = styled.span`
  padding-left: 2rem;
  font-size: larger;
`;

type CenterScreenProps = {
  height: string;
};

const CenterScreen = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: ${(props: CenterScreenProps) => props.height};
  line-height: initial;
`;
const StyledSpinner = styled.svg<{ small?: boolean }>`
  animation: rotate 2s linear infinite;
  width: ${(props) => (props.small ? '25px' : '50px')};
  height: ${(props) => (props.small ? '25px' : '50px')};
  margin: auto;
  vertical-align: middle;

  & .path {
    stroke: green;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default LoadingIndicator;
