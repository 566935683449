import { ProblemDetails } from '@kotipizzagroup/kotipizza-products-api-client';
import { authProvider } from '../../authProvider';
import { AxiosHeaders, AxiosRequestHeaders } from 'axios';
import { getConfiguration, isNotProd } from '../shared/utils/configuration';

export const getRequestHeaders = async (): Promise<AxiosRequestHeaders> => {
  const token = await authProvider.requestApiAccessToken();
  const headers = new AxiosHeaders();
  headers.set('Authorization', `Bearer ${token?.accessToken}`);
  headers.set('Ocp-Apim-Trace', true);
  headers.set('Content-Type', 'application/json');
  return headers;
};

export type EnumEntry = {
  key: string;
  value: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getEnumEntries(givenEnum: any): EnumEntry[] {
  return Object.entries(givenEnum)
    .filter((entry) => !isNaN(Number(entry[0])))
    .map((entry): EnumEntry => {
      return { key: entry[1] as string, value: Number(entry[0]) };
    });
}

export type FieldError = { [key: string]: string[] };

export interface ErrorDetails extends ProblemDetails {
  errors: FieldError;
}

export const isErrorDetails = (value?: unknown): value is ErrorDetails => {
  const details = value as ErrorDetails;

  return details?.status === 400 && details?.errors && Object.keys(details?.errors ?? {}).length > 0;
};

export function showValidationErrors<T>(
  fieldError: FieldError,
  setError: (fieldName: keyof T, error: { type: string; message: string }, options?: { shouldFocus: boolean }) => void,
  t = (value: string) => value
) {
  Object.entries(fieldError).forEach(([key, errors], index) => {
    setError(
      `${key[0].toLowerCase()}${key.slice(1)}` as keyof T,
      {
        type: 'server',
        message: (errors || []).map((error) => t(error)).join('. '),
      },
      { shouldFocus: !index }
    );
  });
}

export const postToSlack = async (text: string, channel = 'campaigns'): Promise<void> => {
  if (isNotProd()) return;

  const accountInfo = await authProvider.getAccountInfo();
  const message = `${text} - ${accountInfo?.name || ''}`;

  const channelId = getConfiguration<string>(`slack.${channel}`, '');

  try {
    await fetch(`https://hooks.slack.com/services/${channelId}`, {
      method: 'POST',
      body: JSON.stringify({ text: message }),
    });
    console.log(`Successfully sent ${text} in Slack`);
  } catch (error) {
    console.error(error);
  }
};
