import { Add, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useCustomSnackBar } from '../../../shared/SnackBar/useCustomSnackBar';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { useIngredientRestoMapping } from '../../../apis/restoIntegration-service';
import { useTranslation } from 'react-i18next';
import {
  allDosingsCacheKey,
  allIngredientsCacheKey,
  updateIngredientDosings,
  useAllProductSizes,
  useIngredient,
} from '../../../apis/products-api';
import { DosingDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { Transition } from '../../../shared/Transition/TransitionUp';

const PIZZA_CATEGORY_NAMES = ['Pizzat', 'Kotzonet', 'Monsterit', 'Luksuslankut'];

export type IngredientDosingDialogProps = {
  open: boolean;
  title?: string;
  ingredientId: number;
  onDialogClose?: () => void;
};

const ErrorTypography = styled(Typography)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.error.main};
`;

interface DosingSizeSelectProps extends GridRenderCellParams {
  disabled: boolean;
}

const DosingSizeSelect: React.FC<DosingSizeSelectProps> = (props) => {
  const apiRef = useGridApiContext();
  const existingProductSizes = Array.from(apiRef.current.getRowModels().values()).flatMap((x) => x.productSizeId);
  const { data: productSizes, isLoading: isProductSizesLoading } = useAllProductSizes();
  const { t } = useTranslation();

  const handleValueChange = (event: SelectChangeEvent<number>) => {
    const newValue = event.target.value;

    const row = apiRef.current.getRow(props.id);

    apiRef.current.setEditCellValue({
      id: props.id,
      field: props.field,
      value: newValue,
    });

    apiRef.current.updateRows([
      {
        ...row,
        productSizeId: newValue,
      },
    ]);
  };

  if (isProductSizesLoading || !productSizes) {
    return null;
  }

  const productSize = productSizes.find((size) => size.productSizeId === props.value);
  const availableProductSizes = productSizes.filter((size) => !existingProductSizes.includes(size.productSizeId));

  if (productSize) {
    availableProductSizes.push(productSize);
  }

  if (props.disabled) {
    return (
      <Box width="100%" padding="0.5em">
        {productSize ? productSize.combinedName : t('ingredients.dosings.chooseProductSize')} {`(id: ${props.value})`}
      </Box>
    );
  }

  if (!productSize && props.value) {
    return (
      <Box width="100%" padding="0.5em">
        {t('ingredients.dosings.missingProductSize')} {`(id: ${props.value})`}
      </Box>
    );
  }

  return (
    <Box width="100%" padding="0.25em">
      {(productSize || !props.value) && (
        <Select
          onOpen={() =>
            apiRef.current.startCellEditMode({
              id: props.id,
              field: props.field,
            })
          }
          onClose={() =>
            apiRef.current.stopCellEditMode({
              id: props.id,
              field: props.field,
            })
          }
          fullWidth
          defaultValue={0}
          value={props.value}
          onChange={handleValueChange}
          renderValue={(value: number) => {
            if (!value) {
              return t('ingredients.dosings.chooseProductSize');
            }

            return productSize?.combinedName;
          }}
        >
          {availableProductSizes.map((size) => (
            <MenuItem key={`${size.productSizeId}-${size.combinedName}`} value={size.productSizeId}>
              {size.combinedName}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
};

interface IDosingGridRow extends DosingDto {
  index: number;
}

const StyledDataGrid = styled(DataGrid)`
  /* Hide the up and down arrows from number inputs */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const RestoMappingStates = {
  loading: 'ingredients.restoMapping.loading',
  missing: 'ingredients.restoMapping.missing',
};

export const IngredientDosingDialog = (props: IngredientDosingDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [removedDosingIds, setRemovedDosingIds] = useState<number[]>([]);
  const { data: ingredient, isLoading, refetch: refetchIngredients } = useIngredient(props.ingredientId);
  const {
    data: ingredientRestoMappings,
    isLoading: isRestoMappingLoading,
    isError,
  } = useIngredientRestoMapping(props.ingredientId);
  const [gridRows, setGridRows] = useState<IDosingGridRow[]>([]);
  const [savingDosings, setSavingDosings] = useState<boolean>(false);
  const snackBar = useCustomSnackBar();
  const queryClient = useQueryClient();
  const { data: productSizes, isLoading: isProductSizesLoading } = useAllProductSizes();

  useEffect(() => {
    setGridRows(
      ingredient?.dosings?.map((dosing, index) => {
        let restoIdStatus = (
          ingredientRestoMappings?.find((x) => x.sizeId == dosing.productSizeId) || {
            restoArticleId: RestoMappingStates.missing,
          }
        ).restoArticleId;
        if (isRestoMappingLoading && !isError) {
          restoIdStatus = RestoMappingStates.loading;
        }
        return { ...dosing, restoIdStatus, index };
      }) || []
    );
  }, [ingredient, ingredientRestoMappings, isError, isRestoMappingLoading]);

  const handleSaveDosings = async () => {
    try {
      setSavingDosings(true);
      await updateIngredientDosings(props.ingredientId, {
        removedDosingsIds: removedDosingIds,
        ingredientDosings: gridRows.map((row) => {
          return {
            ...row,
          };
        }),
      });
      snackBar.showSuccess('Dosings updated successfully');
      await queryClient.invalidateQueries(allIngredientsCacheKey);
      await queryClient.invalidateQueries(allDosingsCacheKey);
      await refetchIngredients();
      props.onDialogClose?.();
    } catch (err) {
      console.error(err);
      snackBar.showError('Failed to update dosings');
    } finally {
      setSavingDosings(false);
    }
  };

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      const row = gridRows.find((row) => row.index === id);

      if (confirm(`Are you sure you want to remove size (id: ${row?.dosingId})`)) {
        setGridRows(gridRows.filter((row) => row.index !== id));
        if (row?.dosingId) {
          setRemovedDosingIds((removedDosingIds) => [...removedDosingIds, row.dosingId as number]);
        }
      }
    },
    [gridRows]
  );

  const addNewDosingRow = () => {
    const lastIndex = [...gridRows].pop()?.index || 0;
    const newIndex = lastIndex + 1;

    const newRow: IDosingGridRow = {
      productSizeId: 0,
      ingredientId: props.ingredientId,
      priceWithVat: 0,
      dosingInGrams: 0,
      minQuantity: 0,
      maxQuantity: 3,
      active: true,
      index: newIndex,
    };

    setGridRows((oldGridRows) => [...oldGridRows, newRow]);
  };

  const handleSizes = () => {
    const pizzaSizes =
      productSizes
        ?.filter((size) => PIZZA_CATEGORY_NAMES.includes(size.productCategoryName))
        .map((size) => size.productSizeId) || [];

    const existingProductSizes: number[] =
      (ingredient?.dosings
        ?.map((dosing) => {
          return dosing.productSizeId;
        })
        .filter(Boolean) as number[]) || [];

    const filterArray = (pizzaSizes: number[], existingProductSizes: number[]) => {
      return pizzaSizes.filter((el: number) => {
        return existingProductSizes.indexOf(el) === -1;
      });
    };

    const filtered = filterArray(pizzaSizes, existingProductSizes);

    setGridRows((oldGridRows) => [
      ...oldGridRows,
      ...filtered.map((sizeId, index) => ({
        productSizeId: sizeId,
        ingredientId: props.ingredientId,
        priceWithVat: 0,
        dosingInGrams: 0,
        minQuantity: 0,
        maxQuantity: 3,
        active: true,
        index: existingProductSizes.length + index,
      })),
    ]);
  };

  const processRowUpdate = useCallback(
    (newRow: GridValidRowModel) => {
      const updatedRow = { ...newRow } as IDosingGridRow;
      setGridRows(gridRows.map((row) => (row.index === newRow.index ? updatedRow : row)));
      return updatedRow;
    },
    [gridRows]
  );

  const updateGridRows = (row: GridValidRowModel, value: string, field: keyof IDosingGridRow) => {
    const rowToUpdate = gridRows[row.index];
    const updatedRow = { ...rowToUpdate, [field]: value };
    setGridRows(gridRows.map((row) => (row.index === rowToUpdate.index ? updatedRow : row)));
  };

  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'productSizeId',
      headerName: 'Tuotekategoria ja koko',
      flex: 3,
      renderCell: (props) => <DosingSizeSelect {...props} disabled={false} />,
      renderEditCell: (props) => <DosingSizeSelect {...props} disabled={false} />,
      sortable: false,
      editable: true,
    },
    {
      field: 'priceWithVat',
      headerName: 'Hinta sis. ALV',
      flex: 1,
      preProcessEditCellProps: (params) => {
        const hasError = isNaN(params.props.value);
        !hasError && updateGridRows(params.row, params.props.value, 'priceWithVat');
        return { ...params.props, error: hasError };
      },
      sortable: false,
      editable: true,
      type: 'number',
      renderCell: (params) => <TextField value={params.value} type="number" />,
    },
    {
      field: 'dosingInGrams',
      headerName: 'Annostus (g)',
      flex: 1,
      sortable: false,
      editable: true,
      type: 'number',
      preProcessEditCellProps: (params) => {
        const hasError = isNaN(params.props.value);
        !hasError && updateGridRows(params.row, params.props.value, 'dosingInGrams');
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => <TextField value={params.value} type="number" />,
    },
    {
      field: 'minQuantity',
      headerName: 'Min',
      flex: 1,
      editable: true,
      sortable: false,
      type: 'number',
      preProcessEditCellProps: (params) => {
        const hasError = isNaN(params.props.value);
        !hasError && updateGridRows(params.row, params.props.value, 'minQuantity');
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => <TextField value={params.value} type="number" />,
    },
    {
      field: 'maxQuantity',
      headerName: 'Max',
      flex: 1,
      editable: true,
      sortable: false,
      type: 'number',
      preProcessEditCellProps: (params) => {
        const hasError = isNaN(params.props.value);
        !hasError && updateGridRows(params.row, params.props.value, 'maxQuantity');
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => <TextField value={params.value} type="number" />,
    },
    {
      field: 'restoIdStatus',
      headerName: 'Resto Id',
      flex: 1,
      sortable: false,
      editable: false,
      type: 'number',
      renderCell: (params) => {
        if (params.value == RestoMappingStates.missing) return <ErrorTypography>{t(params.value)}</ErrorTypography>;
        if (params.value == RestoMappingStates.loading) return t(params.value);
        return params.value;
      },
    },
    {
      field: 'dosingId',
      headerName: 'Dosing Id',
      flex: 1,
      sortable: false,
      editable: false,
      type: 'number',
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Toiminnot',
      cellClassName: 'actions',
      flex: 2,
      sortable: false,
      getActions: ({ id }: GridRowParams) => {
        return [
          <GridActionsCellItem
            showInMenu={false}
            icon={
              <Link color="secondary" component="button" variant="body2">
                remove
              </Link>
            }
            color="inherit"
            onClick={handleDeleteClick(id)}
            label="remove"
          />,
        ];
      },
    },
  ];

  return (
    <Dialog open={props.open} TransitionComponent={Transition} keepMounted maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex">
          <Box alignItems="center" display="flex" flex="1">
            {props.title} - {ingredient?.name} ({gridRows.length || 0} kpl)
          </Box>
          <Box display="flex">
            <IconButton
              onClick={() => {
                props.onDialogClose && props.onDialogClose();
              }}
              aria-label="close"
              color="primary"
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <StyledDataGrid
          loading={isLoading}
          autoHeight
          rowHeight={65}
          hideFooter
          processRowUpdate={processRowUpdate}
          columns={columns}
          rows={gridRows}
          getRowId={(row) => row.index}
          disableRowSelectionOnClick
        />
        <Box marginTop="1em">
          <Button color="primary" variant="outlined" startIcon={<Add />} onClick={addNewDosingRow}>
            Lisää annostus
          </Button>
          <Button
            disabled={isProductSizesLoading}
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={handleSizes}
          >
            Lisää kaikki Pizza-tuotteet
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" padding="1em">
          <LoadingButton
            loading={savingDosings}
            color="primary"
            variant="contained"
            onClick={() => handleSaveDosings()}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
