import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import {
  CAMPAIGNS_WRITE,
  HasAllPermissions,
  LOYALTY_WRITE,
  PRODUCTS_WRITE,
  SHOPS_WRITE,
} from '../../shared/utils/permissions';
import { useUserPermissions } from '../../apis/user-details';
import { Route, Routes, useNavigate } from 'react-router-dom';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import {
  useAllIngredients,
  useAllOutOfStockIngredients,
  useAllOutOfStockProducts,
  useAllProducts,
} from '../../apis/products-api';
import { OutOfStockProductsDetails } from './OutOfStockProductDetails';
import { OutOfStockIngredientDto, OutOfStockProductDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { useAllRestaurants } from '../../apis/restaurants-service';
import dayjs from 'dayjs';
import { DataGridContainer, StyledLink, StyledToolbar, TopBar } from '../IngredientsPage';
import { OutOfStockIngredientDetails } from './OutOfStockIngredientDetails';

export type OutOfStockRow = OutOfStockProductDto &
  OutOfStockIngredientDto & {
    restaurantName?: string;
  };

type OutOfStockList = {
  [key: number]: OutOfStockRow[];
};

type ListData = {
  id: number;
  name?: string;
  outOfStocks: OutOfStockRow[];
  isIngredient: boolean;
};

export const OutOfStockPage: React.FC = () => {
  const { t } = useTranslation();
  const { roles: userPermissions } = useUserPermissions();
  const navigate = useNavigate();
  const { data: outOfStockProducts, isLoading: isOutOfStockProductsLoading } = useAllOutOfStockProducts();
  const { data: outOfStockIngredients, isLoading: isOutOfStockIngredientsLoading } = useAllOutOfStockIngredients();
  const { data: products, isLoading: isProductsLoading } = useAllProducts();
  const { data: ingredients, isLoading: isIngredientsLoading } = useAllIngredients();
  const { data: restaurants, isLoading: isRestaurantsLoading } = useAllRestaurants();

  const hasAdminPermissions = useMemo(
    () => HasAllPermissions(userPermissions, [PRODUCTS_WRITE, LOYALTY_WRITE, SHOPS_WRITE, CAMPAIGNS_WRITE]),
    [userPermissions]
  );

  const isLoading = useMemo(
    () =>
      isOutOfStockProductsLoading ||
      isOutOfStockIngredientsLoading ||
      isProductsLoading ||
      isIngredientsLoading ||
      isRestaurantsLoading,
    [
      isIngredientsLoading,
      isOutOfStockIngredientsLoading,
      isOutOfStockProductsLoading,
      isProductsLoading,
      isRestaurantsLoading,
    ]
  );

  const list = useMemo((): ListData[] => {
    if (isLoading) return [];

    let combinedList: ListData[] = [];

    if (outOfStockIngredients) {
      const outOfStocksByIngredientId: OutOfStockList = outOfStockIngredients.reduce((a, ingredient) => {
        if (ingredient.ingredientId) {
          if (!a[ingredient.ingredientId]) {
            a[ingredient.ingredientId] = [];
          }
          a[ingredient.ingredientId].push({
            ...ingredient,
            restaurantName: restaurants?.find((restaurant) => restaurant.shopExternalId === ingredient.externalShopId)
              ?.displayName,
          });
        }
        return a;
      }, {} as OutOfStockList);

      combinedList = Object.keys(outOfStocksByIngredientId).map((key) => ({
        id: Number(key),
        name: ingredients?.find((i) => i.ingredientId === Number(key))?.name,
        outOfStocks: outOfStocksByIngredientId[Number(key)],
        isIngredient: true,
      }));
    }

    if (outOfStockProducts && hasAdminPermissions) {
      const outOfStocksByProductId: OutOfStockList = outOfStockProducts.reduce((a, product) => {
        if (product.productId) {
          if (!a[product.productId]) {
            a[product.productId] = [];
          }
          a[product.productId].push({
            ...product,
            restaurantName: restaurants?.find((restaurant) => restaurant.shopExternalId === product.externalShopId)
              ?.displayName,
          });
        }
        return a;
      }, {} as OutOfStockList);

      combinedList.push(
        ...Object.keys(outOfStocksByProductId).map((key) => ({
          id: Number(key),
          name: products?.find((p) => p.productId === Number(key))?.name,
          outOfStocks: outOfStocksByProductId[Number(key)],
          isIngredient: false,
        }))
      );
    }

    return combinedList;
  }, [ingredients, isLoading, outOfStockIngredients, outOfStockProducts, products, restaurants, hasAdminPermissions]);

  const handleNewOutOfStockProduct = () => {
    navigate('/out-of-stock/product/new');
  };

  const handleNewOutOfStockIngredient = () => {
    navigate('/out-of-stock/ingredient/new');
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Out of stock product'),
      flex: 1,
      renderCell: (params) => {
        if (!params.row.name) return null;
        const name = `${params.row.name}${params.row.isIngredient ? ` (${t('ingredient')})` : ''}`;

        return (
          <StyledLink
            onClick={() =>
              navigate(`/out-of-stock/${params.row.isIngredient ? 'ingredient' : 'product'}/${params.row.id}`)
            }
          >
            {name}
          </StyledLink>
        );
      },
    },
    {
      field: 'externalShopId',
      headerName: t('Out of stock from restaurant'),
      flex: 1,
      renderCell: (params) => {
        const restaurantAmount = params.row.outOfStocks.filter((outOfStocks: OutOfStockRow) =>
          Boolean(outOfStocks.restaurantName)
        ).length;

        return <div>{`${restaurantAmount} ${t('global.restaurant', { count: restaurantAmount })}`}</div>;
      },
    },
    {
      field: 'added',
      headerName: t('Marked out of stock at'),
      flex: 1,
      renderCell: (params) => {
        const latestAddition = params.row.outOfStocks.sort(
          (a: OutOfStockProductDto | OutOfStockIngredientDto, b: OutOfStockProductDto | OutOfStockIngredientDto) =>
            dayjs(a.added).isBefore(dayjs(b.added)) ? 1 : -1
        )[0];

        return <div>{dayjs(latestAddition.added).format('D.M.YYYY klo HH:mm')}</div>;
      },
    },
    {
      field: 'edit',
      headerName: t('Actions'),
      renderCell: (params) => {
        return (
          <div>
            <StyledLink
              key={params.row.id + '-edit'}
              onClick={() =>
                navigate(`/out-of-stock/${params.row.isIngredient ? 'ingredient' : 'product'}/${params.row.id}`)
              }
            >
              {t('edit')}
            </StyledLink>
          </div>
        );
      },
    },
  ];

  return (
    <Routes>
      <Route path="/ingredient/:id" element={<OutOfStockIngredientDetails />} />
      <Route path="/product/:id" element={<OutOfStockProductsDetails />} />
      <Route
        index
        element={
          <>
            <TopBar>
              <Typography variant="h1">{t('Out of stock products')}</Typography>
              {hasAdminPermissions && (
                <Button
                  disabled={!HasAllPermissions(userPermissions, [PRODUCTS_WRITE])}
                  variant="contained"
                  onClick={handleNewOutOfStockProduct}
                  color="primary"
                >
                  {t('Mark as out of stock product')}
                </Button>
              )}
              <Button
                disabled={!HasAllPermissions(userPermissions || [], [PRODUCTS_WRITE])}
                variant="contained"
                onClick={handleNewOutOfStockIngredient}
                color="primary"
              >
                {t('Mark as out of stock')}
              </Button>
            </TopBar>
            <DataGridContainer display="flex">
              <StyledDataGrid
                disableRowSelectionOnClick
                rows={list || []}
                columns={columns}
                getRowId={(row) => row.id}
                loading={isLoading}
                getRowClassName={() => ''}
              />
            </DataGridContainer>
            <StyledToolbar variant="outlined">
              <Grid display="flex" container spacing={3}>
                <Grid item xs={12} sm={6} />
              </Grid>
            </StyledToolbar>
          </>
        }
      />
    </Routes>
  );
};

export default OutOfStockPage;
