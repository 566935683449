import { CardContent, CardHeader, FormControlLabel, Switch } from '@mui/material';
import { StyledCard } from './Card.styles';
import { ChangeEvent, useEffect, useState } from 'react';

interface Props {
  title: string;
  sizeId: number;
  selected: boolean;
  isLoading: boolean;
  onChange: (sizeId: number, selected: boolean) => void;
}

export const ProductSizeCard = (props: Props): JSX.Element => {
  const { title, sizeId, onChange, selected, isLoading } = props;
  const [sizeSelected, setSizeSelected] = useState(selected);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSizeSelected(checked);
    onChange(sizeId, checked);
  };

  // Update selection after sizes are loaded.
  useEffect(() => {
    if (!isLoading) {
      setSizeSelected(selected);
    }
  }, [isLoading, selected]);

  return (
    <StyledCard>
      <CardHeader title={title} classes={{ root: !sizeSelected ? 'disabled' : '' }} />
      <CardContent>
        <FormControlLabel
          control={<Switch checked={sizeSelected} onChange={handleChange} color="primary" />}
          label={sizeSelected ? 'Käytössä' : 'Ei käytössä'}
        />
      </CardContent>
    </StyledCard>
  );
};
