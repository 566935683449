import { Card } from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .MuiCardHeader-root {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};

    &.disabled {
      background-color: #888;
    }
  }

  .MuiCardContent-root {
    align-items: center;
    text-align: center;
  }
`;
