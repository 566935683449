import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import styled from 'styled-components';

export interface SelectOption {
  title: string;
  value: number | string;
}

interface CustomSelectProps<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError | undefined;
  label: string;
  emptyText?: string;
  showEmptyOption: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  options: SelectOption[];
  sx?: SxProps;
}

const ErrorText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const CustomSelect = <T extends FieldValues>(props: CustomSelectProps<T>): JSX.Element => {
  const { options, isLoading, isDisabled, showEmptyOption, emptyText, label, error, sx = {} } = props;

  const renderSelectValue = (value: number | string) => {
    const correctOption = options.find((x) => x.value === value);
    return <>{correctOption?.title ? <span>{correctOption.title}</span> : <span>{props.emptyText}</span>}</>;
  };

  return (
    <FormControl error={!!error} sx={{ ...sx, minWidth: 190 }}>
      <Controller
        render={(renderProps) => (
          <>
            <InputLabel shrink>{label}</InputLabel>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                <Select
                  variant="outlined"
                  displayEmpty
                  disabled={isDisabled}
                  renderValue={renderSelectValue}
                  ref={renderProps.field.ref}
                  label={label}
                  onChange={renderProps.field.onChange}
                  value={renderProps.field.value || ''}
                >
                  {showEmptyOption ? (
                    <MenuItem key="empty" value="">
                      {emptyText}
                    </MenuItem>
                  ) : null}
                  {options.map((option) => (
                    <MenuItem key={option.title} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>

                <ErrorText>{error?.message}</ErrorText>
              </>
            )}
          </>
        )}
        control={props.control}
        name={props.name}
      />
    </FormControl>
  );
};
