import {
  Alert,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { object, SchemaOf, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomTextFieldInput } from '../../shared/CustomTextFieldInput/CustomTextFieldInput';
import { AutocompleteOff } from '../CampaignsPage/shared';
import {
  allProductMenusCacheKey,
  getSingleProductMenu,
  upsertProductMenu,
  useAllProducts,
  useDefaultProductMenu,
} from '../../apis/products-api';
import { ProblemDetails, ProductMenuDto, ShopProductMenuDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { useAllRestaurants } from '../../apis/restaurants-service';
import { SearchInput } from '../../shared/Search/SearchInput';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';
import { isErrorDetails, showValidationErrors } from '../../apis/utils';
import { FormSwitch } from '../../shared/FormSwitch/FormSwitch';

const formSchema: SchemaOf<ProductMenuDto> = object()
  .shape({
    name: string().required(),
    description: string().nullable(),
  })
  .defined();

const ProductMenuDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [shops, setShops] = useState<ShopProductMenuDto[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);

  const { id } = useParams<{ id?: string }>();
  const isNewMenu = isNaN(Number(id));
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const snackBar = useCustomSnackBar();
  const navigate = useNavigate();
  const upsertProductMenuMutation = useMutation(upsertProductMenu);

  const { data: defaultMenu, isLoading: isDefaultMenuLoading } = useDefaultProductMenu();
  const { data: products, isLoading: isProductsLoading } = useAllProducts();
  const { data: restaurants, isLoading: isRestaurantsLoading } = useAllRestaurants();

  useEffect(() => {
    setIsLoading(isDefaultMenuLoading || isProductsLoading || isRestaurantsLoading);
  }, [isDefaultMenuLoading, isProductsLoading, isRestaurantsLoading]);

  useEffect(() => {
    if (!defaultMenu || isNewMenu) return;

    if (defaultMenu.productMenuId === Number(id)) {
      setIsReadOnly(true);
    }
  }, [defaultMenu, id, isNewMenu]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors, isDirty },
  } = useForm<ProductMenuDto>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      isActive: false,
    },
  });

  useEffect(() => {
    if (!id) return;

    const setInitialMenu = async () => {
      const selectedMenu = await getSingleProductMenu(Number(id));
      if (selectedMenu) {
        reset(selectedMenu);
        setShops(selectedMenu.shopsHavingMenu || []);
        setProductIds(selectedMenu.productIds || []);
      }
    };

    setInitialMenu();
  }, [id, reset]);

  const handleAddRestaurant = (value: string) => {
    if (shops.find((shop) => shop.shopExternalId === value)) return;

    const shopToAdd: ShopProductMenuDto = {
      shopExternalId: value,
      availableForDelivery: true,
      availableForEatIn: true,
      availableForPickup: true,
      productMenuId: Number(id) || -1,
      shopProductMenuId: 0,
    };

    setShops((curr) => [...curr, shopToAdd]);
  };

  const handleAddProduct = (value: string) => {
    if (productIds.find((productId) => productId === Number(value))) return;
    setProductIds((curr) => [...curr, Number(value)]);
  };

  const handleProductMenuSave: SubmitHandler<ProductMenuDto> = async (formData) => {
    try {
      const payload: ProductMenuDto = {
        ...formData,
        shopsHavingMenu: shops,
        productIds,
      };

      if (isNewMenu) {
        payload.productMenuId = undefined;
      }

      // Save product menu
      const response = await upsertProductMenuMutation.mutateAsync(payload);
      await queryClient.invalidateQueries([allProductMenusCacheKey]);

      if (!payload.productMenuId) {
        snackBar.showSuccess('Uusi tuotevalikoima luotu');
        navigate(`/product-menus/${response.id}`);
      } else {
        snackBar.showSuccess('Tuotevalikoima päivitetty');
        navigate('/product-menus');
      }
    } catch (e) {
      const details = e as ProblemDetails;
      if (isErrorDetails(details)) {
        showValidationErrors<ProductMenuDto>(details.errors, setError, t);
      }
      snackBar.showError(t(details?.title || 'Tallentaminen epäonnistui'));
    }
  };

  const SaveButton = () => {
    return (
      <LoadingButton
        onClick={handleSubmit(handleProductMenuSave)}
        startIcon={<Save />}
        loading={isLoading}
        disabled={isLoading || isReadOnly}
        variant="contained"
        color="primary"
      >
        {t('global.save')}
      </LoadingButton>
    );
  };

  return (
    <Box>
      {isReadOnly && <Alert severity="warning">{t('productMenus.details.defaultMenuWarning')}</Alert>}
      <TopBar>
        <Typography variant="h1">
          {t('productMenus.list.title')} / {isNewMenu ? t('productMenus.addMenu') : t('productMenus.editMenu')}
        </Typography>
        <SaveButton />
      </TopBar>
      <Grid item xs={12} width={800} my={1}>
        <Typography variant="body1" mb={2}>
          Tuotevalikoimia käytetään Kotipizzan perusmenun laajentamiseen esim: <strong>Tuotteiden pilotointi</strong>,
          Paikalliset tuotteet. Tuotevalikoima voi olla voimassa yhdessä tai useammassa ravintolassa.
        </Typography>
        <Typography variant="body1" mb={2}>
          Älä tee tuotevalikoimaa kausituotteille tai muille tuotteille, jotka kuuluvat kaikkien Kotipizzojen
          valikoimaan.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">{t('productMenus.details.information')}</Typography>
        <FormSwitch
          name="isActive"
          control={control}
          label={t('productDetails.active')}
          uncheckedLabel={t('productDetails.inactive')}
          disabled={isReadOnly}
        />
        <CustomTextFieldInput
          name="name"
          label={t('productMenus.list.name')}
          control={control}
          isDirty={isDirty}
          error={errors['name']}
          readOnly={isReadOnly}
          textFieldProps={{
            inputProps: AutocompleteOff,
            rows: 1,
            multiline: true,
            margin: 'normal',
          }}
        />
        <CustomTextFieldInput
          name="description"
          label={t('productMenus.list.description')}
          control={control}
          isDirty={isDirty}
          error={errors['description']}
          readOnly={isReadOnly}
          textFieldProps={{
            inputProps: AutocompleteOff,
            rows: 2,
            multiline: true,
            margin: 'normal',
          }}
        />
      </Grid>
      <Grid container item justifyContent="space-between" alignItems="center" my={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h2">Ravintolat</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {!isReadOnly && (
            <SearchInput
              values={restaurants?.map((r) => ({ value: r.shopExternalId, label: r.displayName })) || []}
              onSelect={handleAddRestaurant}
              label="ravintola"
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} my={2}>
        <TableContainer component={Paper}>
          <LinearProgress sx={{ visibility: isRestaurantsLoading ? 'visible' : 'hidden' }} />
          <Table>
            <TableBody>
              {shops.map((shopInMenu) => (
                <TableRow key={shopInMenu.shopExternalId}>
                  <TableCell>
                    <Typography style={{ color: '#009541', fontWeight: 700 }} variant="body2">
                      {restaurants?.find((r) => r.shopExternalId === shopInMenu.shopExternalId)?.displayName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        const shopIndex = shops.indexOf(shopInMenu);
                        const curr = [...shops];
                        curr[shopIndex].availableForDelivery = !curr[shopIndex].availableForDelivery;
                        setShops(curr);
                      }}
                    >
                      <Typography
                        style={{ color: shopInMenu.availableForDelivery ? 'green' : 'red', fontWeight: 700 }}
                        variant="body2"
                      >
                        {shopInMenu.availableForDelivery ? 'Kuljetus' : 'Ei kuljetusta'}
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        const shopIndex = shops.indexOf(shopInMenu);
                        const curr = [...shops];
                        curr[shopIndex].availableForPickup = !curr[shopIndex].availableForPickup;
                        setShops(curr);
                      }}
                    >
                      <Typography
                        style={{ color: shopInMenu.availableForPickup ? 'green' : 'red', fontWeight: 700 }}
                        variant="body2"
                      >
                        {shopInMenu.availableForPickup ? 'Nouto' : 'Ei noutoa'}
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        const shopIndex = shops.indexOf(shopInMenu);
                        const curr = [...shops];
                        curr[shopIndex].availableForEatIn = !curr[shopIndex].availableForEatIn;
                        setShops(curr);
                      }}
                    >
                      <Typography
                        style={{ color: shopInMenu.availableForEatIn ? 'green' : 'red', fontWeight: 700 }}
                        variant="body2"
                      >
                        {shopInMenu.availableForEatIn ? 'Paikanpäällä' : 'Ei paikanpäällä'}
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {!isReadOnly && (
                      <Button
                        onClick={() => {
                          setShops((curr) => curr.filter((shop) => shop.shopExternalId !== shopInMenu.shopExternalId));
                        }}
                      >
                        {t('global.delete')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography variant="h2">Tuotteet</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {!isReadOnly && (
            <SearchInput
              values={
                products
                  ?.filter((p) => p.productId && !defaultMenu?.productIds?.includes(p.productId))
                  .map((p) => ({ value: p.productId?.toString() || '', label: p.name })) || []
              }
              onSelect={handleAddProduct}
              label="tuote"
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} my={2}>
        <TableContainer component={Paper}>
          <LinearProgress sx={{ visibility: isProductsLoading ? 'visible' : 'hidden' }} />
          <Table>
            <TableBody>
              {productIds.map((productId) => (
                <TableRow key={productId}>
                  <TableCell>
                    <Typography style={{ color: '#009541', fontWeight: 700 }} variant="body2">
                      {products?.find((p) => p.productId === productId)?.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {!isReadOnly && (
                      <Button
                        onClick={() => {
                          setProductIds(productIds.filter((pId) => pId !== productId));
                        }}
                      >
                        {t('global.delete')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <SaveButton />
      <BotBar />
    </Box>
  );
};

const BotBar = styled.div`
  height: 100px;
  width: 100%;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export default ProductMenuDetails;
