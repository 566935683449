import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)`
  background-color: white;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .cell-link {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  .grid-row-not-active {
    background-color: ${({ theme }) => theme.palette.error.light};

    &:hover {
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }

  /* HACK: material ui data grid does not support the multiline rows :/ This overwrites the max height properties set by material ui LIB
    ref: https://github.com/mui-org/material-ui-x/issues/417
  */

  .MuiDataGrid-renderingZone {
    max-height: none !important;
  }

  .MuiDataGrid-cell {
    max-height: none !important;
    white-space: normal;
  }

  .MuiDataGrid-row {
    max-height: none !important;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: #fff;

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }

      &.grid-row-not-active {
        background-color: ${({ theme }) => theme.palette.error.main};

        &:hover {
          background-color: ${({ theme }) => theme.palette.error.dark};
        }
      }
    }
  }
`;

export default StyledDataGrid;
