import React, { useContext, useState, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { ConfirmContext } from './ConfirmContext';

export const ConfirmDialog: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const {
    open,
    texts: { title, description, confirmText, cancelText, loadingText },
    handlers: { onCancel, onConfirm },
  } = useContext(ConfirmContext);

  const handleConfirmClick = async () => {
    setLoading(true);
    await onConfirm();
  };

  useEffect(() => {
    setLoading(false);
  }, [open]);

  return (
    <Dialog open={open} keepMounted maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex">
          <Box alignItems="center" display="flex" flex="1">
            {title}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box marginTop="1em">
          <Typography component="div" paragraph fontWeight="bold" display="block" variant="body2">
            {description}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" padding="1em" justifyContent="space-between" width="100%">
          <Button color="primary" variant="contained" onClick={handleConfirmClick} disabled={loading}>
            {loading ? loadingText : confirmText}
          </Button>
          <Button color="error" variant="contained" onClick={onCancel} disabled={loading}>
            {cancelText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
