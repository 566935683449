import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../LoadingIndicator';

const LoadingOverlay: React.FC<{ message?: string; height?: string; small?: boolean }> = ({
  message,
  height,
  small,
}) => {
  if (!height) {
    height = '100vh';
  }
  return (
    <Container>
      <LoadingIndicator message={message} height={height} small={small} />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
`;

export default LoadingOverlay;
