import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserPermissions } from './app/apis/user-details';
import LoadingIndicator from './app/shared/LoadingIndicator';
import {
  CampaignsPermissionsKey,
  HasAllPermissions,
  LoyaltyPermissionsKey,
  ProductsPermissionsKey,
  ShopsPermissionsKey,
} from './app/shared/utils/permissions';

interface GuardedRouteProps {
  requiredPermissions: Array<string>;
}

const Error = () => <p>Error</p>;

export const GuardedRoute = ({ requiredPermissions }: GuardedRouteProps) => {
  const {
    isErrorCampaigns,
    isErrorLoyalty,
    isLoadingCampaigns,
    isLoadingLoyalty,
    isErrorProducts,
    isLoadingProducts,
    isLoadingShops,
    isErrorShops,
    roles,
  } = useUserPermissions();

  const location = useLocation();

  if (isErrorCampaigns && requiredPermissions.some((x) => x.startsWith(CampaignsPermissionsKey))) {
    return <Error />;
  }

  if (isErrorLoyalty && requiredPermissions.some((x) => x.startsWith(LoyaltyPermissionsKey))) {
    return <Error />;
  }

  if (isErrorProducts && requiredPermissions.some((x) => x.startsWith(ProductsPermissionsKey))) {
    return <Error />;
  }

  if (isErrorShops && requiredPermissions.some((x) => x.startsWith(ShopsPermissionsKey))) {
    return <Error />;
  }

  if (isLoadingCampaigns || isLoadingLoyalty || isLoadingProducts || isLoadingShops) {
    return <LoadingIndicator message="Loading..." />;
  }

  const hasRequiredPermissions = HasAllPermissions(roles || [], requiredPermissions);

  if (!hasRequiredPermissions) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
