import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  IngredientRestoMappingDto,
  ProductRestoMappingDto,
  ProductsApiClient,
} from '@kotipizzagroup/kotipizza-products-api-client';
import { getConfiguration } from '../shared/utils/configuration';
import axios from 'axios';
import { getRequestHeaders } from './utils';

class RestoMappingClient extends ProductsApiClient {
  private static classInstance: RestoMappingClient;

  constructor() {
    const baseUrl = getConfiguration<string>('productsService.root', '');

    const client = axios.create({
      timeout: 10000,
      transformResponse: [],
    });
    client.interceptors.request.use(async (config) => {
      config.headers = await getRequestHeaders();
      return config;
    });

    // @TODO fix this by updating axios version in api client
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(baseUrl, client);
  }

  public static getInstance(): RestoMappingClient {
    if (!this.classInstance) {
      this.classInstance = new RestoMappingClient();
    }
    return this.classInstance;
  }
}

export const fetchProductRestoMappings = async (id: number): Promise<ProductRestoMappingDto[]> => {
  return RestoMappingClient.getInstance().getProductMappings(id);
};

export const fetchIngredientRestoMappings = async (id: number): Promise<IngredientRestoMappingDto[]> => {
  return RestoMappingClient.getInstance().getIngredientMappings(id);
};

export const useIngredientRestoMapping = (
  id: number,
  enabled = true,
  refetch = false
): UseQueryResult<IngredientRestoMappingDto[], unknown> =>
  useQuery<IngredientRestoMappingDto[]>(['ingredientRestoMapping', id], () => fetchIngredientRestoMappings(id), {
    refetchOnMount: refetch,
    refetchOnWindowFocus: refetch,
    retry: 1,
    enabled,
  });

export const useProductRestoMapping = (
  id: number,
  enabled = true,
  refetch = false
): UseQueryResult<ProductRestoMappingDto[], unknown> =>
  useQuery<ProductRestoMappingDto[]>(['ingredientRestoMapping', id], () => fetchProductRestoMappings(id), {
    refetchOnMount: refetch,
    refetchOnWindowFocus: refetch,
    retry: false,
    enabled,
  });
