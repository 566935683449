import { Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { StateMachineProvider } from 'little-state-machine';
import { GridColDef } from '@mui/x-data-grid';
import { lazy, useEffect, useMemo, useState } from 'react';
import { HasAllPermissions, PRODUCTS_WRITE } from '../../shared/utils/permissions';
import { useUserPermissions } from '../../apis/user-details';
import styled from 'styled-components';
import { IngredientDosingDialog } from './components/IngredientDosingDialog';
import { Search } from '@mui/icons-material';
import Fuse from 'fuse.js';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import { useAllIngredients } from '../../apis/products-api';

const IngredientDetails = lazy(() => import('./IngredientDetails'));

export const IngredientsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { roles: userPermissions } = useUserPermissions();
  const navigate = useNavigate();

  const [showNotActive, setShowNotActive] = useState(false);
  const [ingredientDetailsDialogOpen, setIngredientDetailsDialogOpen] = useState(false);
  const [ingredientDosingDialogId, setIngredientDosingDialogId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [copied, setCopied] = useState('');

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied('');
      }, 1000);
    }
  }, [copied]);

  const toggleNotActive = () => {
    setShowNotActive((prev) => !prev);
  };

  const { data: initialData, isLoading } = useAllIngredients(true, {
    select: (data) => {
      return data?.map((ingredient) => {
        return {
          ...ingredient,
          dosingSummary: ingredient.dosings?.length,
          id: ingredient.ingredientId,
        };
      });
    },
  });
  const notActiveCount = (initialData ?? []).filter((ingredient) => !ingredient.isActive).length;

  const [filteredRows, setFilteredRows] = useState(initialData ?? []);

  const fuse = useMemo(
    () =>
      new Fuse(initialData || [], {
        minMatchCharLength: 3,
        threshold: 0.2,
        ignoreLocation: true,
        keys: ['name', 'ingredientId', 'ingredientCategoryName'],
      }),
    [initialData]
  );

  const handleNewIngredient = () => {
    navigate('/ingredients/new');
  };

  useEffect(() => {
    const activeData = showNotActive ? initialData : initialData?.filter((x) => x.isActive);

    if (searchValue.length >= 3) {
      fuse.setCollection(activeData || []);
      setFilteredRows([...fuse.search(searchValue).map((result) => result.item)]);
    }
    if (searchValue.length < 3) {
      setFilteredRows(activeData || []);
    }
  }, [fuse, initialData, searchValue, showNotActive, setFilteredRows]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      flex: 0.5,
      renderCell: (params) => {
        const handleClick = () => {
          navigator.clipboard.writeText(params.row.ingredientId);
          setCopied(params.row.ingredientId);
        };
        return (
          <IngredientInfo>
            <StyledLink onClick={handleClick}>{copied === params.row.ingredientId ? 'kopioitu' : params.id}</StyledLink>
          </IngredientInfo>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Tuote',
      flex: 3,
      renderCell: (params) => {
        const handleClick = () => {
          navigate('/ingredients/' + params.id);
        };
        return (
          <IngredientInfo>
            <StyledLink onClick={handleClick}>{params.value}</StyledLink>
            <CategoryText>{params.row.ingredientCategoryName}</CategoryText>
          </IngredientInfo>
        );
      },
    },
    {
      field: 'dosingSummary',
      headerName: 'Annostus',
      flex: 2,
      renderCell: (params) => {
        const handleClick = () => {
          setIngredientDetailsDialogOpen(true);
          setIngredientDosingDialogId(params.row.ingredientId);
        };
        if (params.value == 0) {
          return (
            <StyledLink color="#FF0000" onClick={handleClick}>
              {params.value} annostusta
            </StyledLink>
          );
        }
        return <StyledLink onClick={handleClick}>{params.value} annostusta</StyledLink>;
      },
    },
    {
      field: 'description',
      headerName: 'Kuvaus',
      flex: 6,
    },
    { field: 'displayOrder', headerName: 'Järjestys', flex: 1 },
    {
      field: 'isActive',
      headerName: 'Tila',
      flex: 1,
      renderCell: (params) => {
        return (params.value as boolean) ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
  ];

  return (
    <StateMachineProvider>
      <Routes>
        <Route path=":id" element={<IngredientDetails />} />
        <Route
          index
          element={
            <>
              {ingredientDosingDialogId && (
                <IngredientDosingDialog
                  title="Ingredient dosings"
                  ingredientId={ingredientDosingDialogId as unknown as number}
                  open={ingredientDetailsDialogOpen}
                  onDialogClose={() => setIngredientDetailsDialogOpen(false)}
                />
              )}
              <TopBar>
                <Typography variant="h1">{t('ingredients.title')}</Typography>
                <Button
                  disabled={!HasAllPermissions(userPermissions || [], [PRODUCTS_WRITE])}
                  variant="contained"
                  onClick={handleNewIngredient}
                  color="primary"
                >
                  {t('ingredients.addIngredient')}
                </Button>
              </TopBar>

              <StyledToolbar variant="outlined">
                <Grid display="flex" container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SearchInput
                      id="search-ingredients"
                      placeholder={t('global.search')}
                      variant="outlined"
                      value={searchValue}
                      autoComplete="off"
                      onChange={(e) => {
                        e.preventDefault();
                        setSearchValue(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid display="flex" alignItems="center" item xs={12} sm={6}>
                    <FormControlLabel
                      control={<Switch size="small" checked={showNotActive} onChange={toggleNotActive} />}
                      label={`${t('ingredients.showInactive')} (${notActiveCount} kpl)`}
                    />
                  </Grid>
                </Grid>
              </StyledToolbar>

              <DataGridContainer display="flex">
                <StyledDataGrid
                  disableRowSelectionOnClick
                  rows={filteredRows || []}
                  columns={columns}
                  getRowId={(row) => row.ingredientId}
                  getRowClassName={(params) => (params.row.isActive ? '' : 'grid-row-not-active')}
                  loading={isLoading}
                />
              </DataGridContainer>
            </>
          }
        />
      </Routes>
    </StateMachineProvider>
  );
};

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;

const SearchInput = styled(TextField)`
  margin-right: 2em;
  flex: 0 1 500px;
  width: 100%;
`;

export const StyledToolbar = styled(Paper)`
  margin-bottom: 1em;
  display: flex;
  padding: 1em;
`;

export const DataGridContainer = styled(Box)`
  height: calc(100vh - 300px);
`;

export const StyledLink = styled(Link)`
  font-size: 15px;
`;

const IngredientInfo = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledLink} {
    font-weight: bold;
    padding-bottom: 0px;
  }
`;

const CategoryText = styled.p`
  margin: 0px;
`;

export default IngredientsPage;
