import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import React, { createContext, useContext, useEffect } from 'react';

type MapContext = {
  mapEl: HTMLDivElement | null;
  map: mapboxgl.Map | null;
  draw: MapboxDraw | null;
};

const defaultContext: MapContext = {
  mapEl: null,
  map: null,
  draw: null,
};

const MapContext = createContext<MapContext>(defaultContext);

export const MapContextProvider = ({ children }: React.PropsWithChildren) => {
  return <MapContext.Provider value={defaultContext}>{children}</MapContext.Provider>;
};

export const useMapCtx = (): MapContext => {
  const ctx = useContext(MapContext);

  useEffect(() => {
    if (!ctx.map) return;

    return () => {
      ctx.map?.remove();
      ctx.map = null;
      ctx.mapEl = null;
      ctx.draw = null;
    };
  }, [ctx]);

  return ctx;
};
